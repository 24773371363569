import React from "react";
import Text from "../UI/Text";
import CardHeading from "../../components/ui/CardHeading";
import { HiOutlineDocumentText } from "react-icons/hi2";

const LeadFields = ({ data }) => {
  return (
    <div className="card">
      <CardHeading Icon={HiOutlineDocumentText} text="Lead Fields" />

      {data?.map((item, i) => (
        <div key={i}>
          {item.values && <Text title={item.name} text={item.values} />}
        </div>
      ))}
    </div>
  );
};

export default LeadFields;
