import React from "react";

const ButtonPrimary = ({
  Icon,
  iconOrder = 1,
  text = "Save",
  type = "submit",
  className,
  buttonStatus,
  action,
}) => {
  return (
    <>
      <button
        className={`${
          buttonStatus ? "btn-theme-secondary" : "btn-theme-primary"
        }  ${className}`}
        type={type}
        onClick={action && action}
        disabled={buttonStatus}
      >
        {Icon && iconOrder === 1 ? (
          <Icon size={20} className={text !== "" && "me-1"} />
        ) : null}
        {text}
        {Icon && iconOrder === 2 ? <Icon size={20} className="ms-1" /> : null}
      </button>
    </>
  );
};

export default ButtonPrimary;
