import React from "react";

const CardHeading = ({ Icon, text }) => {
  return (
    <div className="d-flex align-items-center text-gray fw-600 mb-16px teal-500">
      {Icon && <Icon size={18} className="mr-4px" />}
      <span className="fs-16px">{text}</span>
    </div>
  );
};

export default CardHeading;
