import { AiOutlineUser } from "react-icons/ai";

const leadMenuProviders = [
  {
    title: "Providers",
    path: "/lead/providers",
    Icon: AiOutlineUser,
  },
];

export default leadMenuProviders;
