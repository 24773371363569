import React from "react";
import CategoryForm from "./CategoryForm";
import { useParams } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { WebGet, WebPut } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";

const CategoryEdit = () => {
  const { id } = useParams();
  const { mutateAsync } = WebPut();

  const { data, error, isLoading, isError, refetch } = WebGet(
    "key",
    `/BlogCategoryAdmin/Get/${id}`
  );

  if (isLoading) return <Loading />;

  if (isError) return <ErrorMessage message={error.message} />;

  const defaultData = {
    id: data.data.id,
    name: data.data.name,
    details: data.data.details,
  };

  return (
    <>
      <BackBtn text="Back to Category" path="/web/category" />
      <CategoryForm
        mutateAsync={mutateAsync}
        defaultData={defaultData}
        submitPath="/BlogCategoryAdmin/update"
        navigatePath="/web/category"
        refetch={refetch}
      />
    </>
  );
};

export default CategoryEdit;
