import { FiCalendar, FiTarget } from "react-icons/fi";
import { FaWpforms } from "react-icons/fa";
import settingMenu from "./settingMenu";
import leadMenu from "./leadMenu";
// import webMenu from "./webMenu";

const mainmenu = [
  {
    title: "Lead",
    Icon: FiTarget,
    path: "/lead",
    submenu: leadMenu,
  },

  {
    title: "Event",
    Icon: FiCalendar,
    path: "/event",
  },
  // {
  //   title: "Web",
  //   Icon: FiMonitor,
  //   path: "/web/index",
  //   submenu: webMenu,
  // },
  {
    title: "Form Builder",
    Icon: FaWpforms,
    path: "/formbuild",
    submenu: settingMenu,
  },
];

export default mainmenu;
