import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Post } from "../../../api/method";
import Input from "../../../components/ui/Input";
import CloseBtn from "../../../components/buttons/CloseBtn";
import TextArea from "../../../components/ui/TextArea";
import Status from "../../../components/ui/Status";
import DDByUrl from "../../../components/ui/DDByUrl";
import CardHeading from "../../../components/ui/CardHeading";
import { IoMdCheckboxOutline } from "react-icons/io";

const schema = yup.object({
  id: yup.string(),
  status: yup.number().min(1, "Required"),
  note: yup.string().required("Note is required"),
});

const WebStatus = ({ data, refetch, postUrl, action }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const { mutateAsync } = Post();
  const defaultData = {
    id: data.id,
    status: data.status,
    note: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const submitData = {
      id: formData.id,
      status: formData.status,
      note: formData.note,
    };

    setIsSubmit(true);
    mutateAsync({
      path: postUrl,
      formData: submitData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch && refetch();
          action && action();
          reset();
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
    setIsSubmit(false);
  };

  return (
    <div className={!action && "card"}>
      <div className="d-flex justify-content-between">
        <CardHeading Icon={IoMdCheckboxOutline} text="Status" />

        {action ? (
          <CloseBtn action={action} />
        ) : (
          <span>
            <Status text={data.statusName} />
          </span>
        )}
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="hidden"
          name="id"
          register={register}
          defaultValue={defaultData.id}
        />

        <DDByUrl
          register={register}
          label="Are you wants to change status ?"
          name="status"
          placeholder="Select Status"
          url="WebsiteLeadStatus"
          defaultValue={defaultData.status}
          error={errors?.status?.message}
        />

        <TextArea
          label="Note"
          register={register}
          name="note"
          placeholder="Write an short note"
          defaultValue={defaultData.note}
          error={errors?.note?.message}
        />

        <ButtonPrimary
          className={action && "w-100"}
          text="Done"
          buttonStatus={isSubmit}
        />
      </Form>
    </div>
  );
};

export default WebStatus;
