import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const ViewBtn = ({ data, path, action }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    data && path && navigate(path, { state: data });
    !data && path && navigate(path);
    action && action();
  };

  return (
    <>
      <AiOutlineEye
        size={20}
        className="pointer ml-4px mr-4px teal-500"
        onClick={handleEdit}
      />
    </>
  );
};

export default ViewBtn;
