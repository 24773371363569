import { lazy } from "react";

const PrivateRoute = lazy(() => import("./layout/PrivateRoute"));
const Index = lazy(() => import("./Index"));

const eventRoute = {
  path: "/event",
  element: <PrivateRoute />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: "/event",
      element: <Index />,
    },
  ],
};

export default eventRoute;
