import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const BackBtn = ({ text = "Back", path }) => {
  const navigate = useNavigate();

  const back = () => {
    path ? navigate(path) : window.history.back();
  };

  return (
    <div className="back-btn mb-16px inline-block" onClick={back}>
      <FiArrowLeft size={24} /> {text}
    </div>
  );
};

export default BackBtn;
