import React from "react";
import { WebDelete } from "../../api/method";
import { Col, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import ButtonLight from "./ButtonLight";
import ButtonPrimary from "./ButtonPrimary";
import { toast } from "react-toastify";

const DeleteBtnWeb = ({
  text = "Are you confirm delete it ?",
  submitPath,
  refetch,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { mutateAsync } = WebDelete();

  const onSubmit = (formData) => {
    mutateAsync({
      path: submitPath,
      formData: formData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
          setModalShow(false);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <>
      <span className="pointer ml-4px" onClick={() => setModalShow(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M16.72 4.98487C14.2225 4.73737 11.7099 4.60986 9.20497 4.60986C7.71997 4.60986 6.23497 4.68486 4.74997 4.83486L3.21997 4.98487"
            stroke="#F5365C"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.34497 4.2275L7.50998 3.245C7.62997 2.53249 7.71997 2 8.98747 2H10.9524C12.22 2 12.3175 2.5625 12.43 3.25249L12.5949 4.2275"
            stroke="#F5365C"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1083 7.35498L14.6208 14.9075C14.5382 16.0849 14.4707 17 12.3782 17H7.56325C5.47075 17 5.40325 16.0849 5.32075 14.9075L4.83325 7.35498"
            stroke="#F5365C"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.71606 12.875H11.2135"
            stroke="#F5365C"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.09497 9.875H11.845"
            stroke="#F5365C"
            strokeWidth="1.35"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
      >
        <Modal.Body>
          <h4 className="mb-3 text-center">{text}</h4>
          <Row>
            <Col>
              <ButtonPrimary
                className="w-100"
                text="Yes"
                action={() => onSubmit()}
              />
            </Col>
            <Col>
              <ButtonLight
                className="w-100"
                text="No"
                action={() => setModalShow(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteBtnWeb;
