import {
  AiOutlineAppstore,
  AiOutlinePicCenter,
  AiOutlineUnorderedList,
  AiOutlineUser,
} from "react-icons/ai";
// import { FiDatabase, FiTrendingUp } from "react-icons/fi";
import {
  // MdOutlineCampaign,
  // MdOutlineFormatListNumbered,
  MdOutlineSettings,
} from "react-icons/md";

const leadMenuAdmin = [
  {
    title: "Dashboard",
    Icon: AiOutlineAppstore,
    path: "/lead",
  },
  {
    title: "Student Lead",
    Icon: AiOutlineUnorderedList,
    path: "/lead/list",
  },
  {
    title: "UAPP.UK Lead",
    Icon: AiOutlinePicCenter,
    path: "/lead/web",
    submenu: [
      {
        title: "Affiliates",
        path: "/lead/affiliates",
      },
      {
        title: "Ambassadors",
        path: "/lead/ambassadors",
      },
      {
        title: "Partners",
        path: "/lead/partners",
      },
      {
        title: "Consultants",
        path: "/lead/consultants",
      },
      {
        title: "Contact Us",
        path: "/lead/contact",
      },
      {
        title: "Providers",
        path: "/lead/providers",
      },
    ],
  },
  // {
  //   title: "Source",
  //   Icon: FiDatabase,
  //   path: "/lead/source",
  // },
  // {
  //   title: "Performance",
  //   Icon: FiTrendingUp,
  //   path: "/lead/performance",
  // },
  // {
  //   title: "Campaign",
  //   Icon: MdOutlineCampaign,
  //   path: "/lead/campaign",
  // },
  {
    title: "Consultant",
    Icon: AiOutlineUser,
    path: "/lead/assignConsultant",
  },
  {
    title: "Setting",
    Icon: MdOutlineSettings,
    path: "/lead/setting",
  },
];

export default leadMenuAdmin;
