import React from "react";
import { Accordion } from "react-bootstrap";
import SideMenu from "./SideMenu";

const NestedMenu = ({ menu, action }) => {
  return (
    <>
      <Accordion>
        {menu.map((item, i) => (
          <Accordion.Item key={i} eventKey={i} className="border-0">
            {item?.submenu?.length > 0 ? (
              <>
                <Accordion.Header>
                  <item.Icon size={24} className="me-1" />
                  <span className="fs-16px">{item.title}</span>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  {item.submenu.map((subitem, j) => (
                    <Accordion key={j} className="ms-3">
                      <Accordion.Item key={j} eventKey={j} className="border-0">
                        {subitem?.submenu?.length > 0 ? (
                          <>
                            <Accordion.Header>
                              <subitem.Icon size={24} className="me-1" />
                              <span className="fs-16px">{subitem.title}</span>
                            </Accordion.Header>
                            <Accordion.Body className="p-0 ms-3">
                              {subitem.submenu.map((item, k) => (
                                <SideMenu
                                  key={k}
                                  action={action}
                                  title={item.title}
                                  Icon={item.Icon}
                                  path={item.path}
                                />
                              ))}
                            </Accordion.Body>
                          </>
                        ) : (
                          <SideMenu
                            key={j}
                            action={action}
                            title={subitem.title}
                            Icon={subitem.Icon}
                            path={subitem.path}
                          />
                        )}
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </Accordion.Body>
              </>
            ) : (
              <SideMenu
                key={i}
                action={action}
                title={item.title}
                Icon={item.Icon}
                path={item.path}
              />
            )}
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default NestedMenu;
