import { lazy } from "react";
import PrivateRoute from "./layout/PrivateRoute";
import Profile from "./profile/Profile";
import Setting from "./setting/Setting";
import ConsultantPerformance from "./performance/ConsultantPerformance";
import MetaAdd from "./setting/meta/MetaAdd";
import MetaEdit from "./setting/meta/MetaEdit";
import Campaign from "./campaign/Campaign";
import Affiliates from "./webLead/Affiliates";
import Ambassadors from "./webLead/Ambassadors";
import Partners from "./webLead/Partners";
import Consultants from "./webLead/Consultants";
import Contact from "./webLead/Contact";
import Providers from "./webLead/Providers";
import WebDetails from "./webLead/details/WebDetails";
import AssignConsultant from "./assignConsultant/AssignConsultant";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Source = lazy(() => import("./source/Source"));
const LeadList = lazy(() => import("./lead/LeadList"));

const leadRoute = {
  path: "/lead",
  element: <PrivateRoute />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: "/lead",
      element: <Dashboard />,
    },
    {
      path: "/lead/list",
      element: <LeadList />,
    },
    {
      path: "/lead/affiliates",
      element: <Affiliates />,
    },
    {
      path: "/lead/ambassadors",
      element: <Ambassadors />,
    },
    {
      path: "/lead/partners",
      element: <Partners />,
    },
    {
      path: "/lead/consultants",
      element: <Consultants />,
    },
    {
      path: "/lead/contact",
      element: <Contact />,
    },
    {
      path: "/lead/providers",
      element: <Providers />,
    },

    {
      path: "/lead/source",
      element: <Source />,
    },
    {
      path: "/lead/performance",
      element: <ConsultantPerformance />,
    },
    {
      path: "/lead/campaign",
      element: <Campaign />,
    },
    {
      path: "/lead/profile/:id",
      element: <Profile />,
    },
    {
      path: "/lead/webDetails",
      element: <WebDetails />,
    },
    {
      path: "/lead/assignConsultant",
      element: <AssignConsultant />,
    },
    {
      path: "/lead/setting",
      element: <Setting />,
    },
    {
      path: "/lead/meta_connect",
      element: <MetaAdd />,
    },
    {
      path: "/lead/meta/edit",
      element: <MetaEdit />,
    },
  ],
};

export default leadRoute;
