import { AiOutlineUser } from "react-icons/ai";

const leadMenuPartners = [
  {
    title: "Partners",
    path: "/lead/partners",
    Icon: AiOutlineUser,
  },
];

export default leadMenuPartners;
