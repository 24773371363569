import { FiTarget } from "react-icons/fi";
import leadMenu from "./leadMenu";

const mainmenuConsultant = [
  {
    title: "Lead",
    Icon: FiTarget,
    path: "/lead",
    submenu: leadMenu,
  },
];

export default mainmenuConsultant;
