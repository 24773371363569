import React from "react";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import EditBtn from "../../components/buttons/EditBtn";
import { WebGet } from "../../api/method";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import ProfilePic from "../../components/ui/ProfilePic";
import DeleteBtnWeb from "../../components/buttons/DeleteBtnWeb";
import Paginations from "../../components/ui/Paginations";
import Input from "../../components/ui/Input";

const BlogList = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const { data, error, isLoading, isError, refetch } = WebGet(
    "key",
    `BlogPostAdmin/GetAll?page=${currentPage}&pageSize=${dataPerPage}&query=${query}&createdOn=${0}&onlypublished=${0}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  let list = data.data;
  return (
    <>
      <div className="text-end mb-30px">
        <Link to={`/web/blog/add`}>
          <ButtonPrimary Icon={AiOutlinePlus} text="Add Blog" />
        </Link>
      </div>

      <Input
        placeholder="Search..."
        defaultValue={query}
        onChange={(e) => setQuery(e.target.value)}
        className="mb-3 me-2"
      />

      {list.length > 0 && (
        <div className="table-div">
          <Table responsive>
            <thead>
              <tr>
                <th>Logo</th>
                <th>Personal Info</th>
                <th>From</th>
                <th>Contact</th>
                <th>Rating</th>
                <th>Is Published</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <ProfilePic filrUrl={item.companyLogo} />
                  </td>
                  <td className="d-flex justify-content-start">
                    <ProfilePic filrUrl={item.avater} className="me-1" />
                    <span>
                      <b>{item.name}</b>
                      <br />
                      {item.designation}
                    </span>
                  </td>
                  <td>{item.reviewFromName}</td>
                  <td>{item.review}</td>
                  <td>{item.ratings}</td>
                  <td>{item.isPublished ? "True" : "False"}</td>

                  <td>
                    <div className="d-flex justify-content-end">
                      <EditBtn path={`/web/review/edit/${item.id}`} />
                      <DeleteBtnWeb
                        submitPath={`ReviewAdmin/Delete/${item.id}`}
                        refetch={refetch}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginations
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataPerPage={dataPerPage}
            setDataPerPage={setDataPerPage}
            // totalData={data?.totalEntity}
            totalData={150}
          />{" "}
        </div>
      )}
    </>
  );
};

export default BlogList;
