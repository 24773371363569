import React from "react";
import { Form } from "react-bootstrap";
import ErrorText from "./ErrorText";

const DDByList = ({
  label,
  name,
  select = "Select",
  list,
  defaultValue,
  error,
  register,
  placeholder,
  action,
  className = "mb-3",
}) => {
  const handleChange = (e) => {
    action && action(e.target.value);
  };

  return (
    <>
      <Form.Group className={className}>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Select
          {...register(name)}
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          <option value={0}> {placeholder ? placeholder : select} </option>

          {list?.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
        <ErrorText error={error} />
      </Form.Group>
    </>
  );
};

export default DDByList;
