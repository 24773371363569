import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckOne from "../../../components/ui/CheckOne";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import ErrorMessage from "../../../components/ui/ErrorMessage";

const MetaPages = ({ pages, mutateAsync, refetch, action }) => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [pageList, setPageList] = useState(pages);

  const handleChecked = (i, e) => {
    const values = pageList;
    values.metaPages[i].is_checked = e.target.checked;
    setPageList(values);
  };

  const onSubmit = () => {
    setIsSubmit(true);

    mutateAsync({
      path: "/MetaPage",
      formData: pageList,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch && refetch();
          action && action()
          navigate("/lead/setting");
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
    setIsSubmit(false);
  };

  if (!pageList?.metaPages?.length > 0)
    return <ErrorMessage message="No Meta Page Found" />;
  return (
    <>
      <div className={`${action && "h-300px"}`}>
        {pageList?.metaPages?.map((item, i) => (
          <div key={i}>
            <CheckOne
              label={item.name}
              defaultValue={item.is_checked}
              onChange={(e) => handleChecked(i, e)}
              className="fw-600 mb-3"
            />
          </div>
        ))}
      </div>

      <ButtonPrimary text="Submit" buttonStatus={isSubmit} action={onSubmit} />
    </>
  );
};

export default MetaPages;
