import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ErrorText from "./ErrorText";

const CheckSwitch = ({
  label,
  name,
  register,
  defaultValue,
  error,
  action,
}) => {
  const [isCheck, setIsCheck] = useState(defaultValue);

  return (
    <>
      <Form.Check
        {...register(name)}
        type="switch"
        onClick={() => {
          setIsCheck(!isCheck);
          action && action();
        }}
        checked={isCheck}
        id={label}
        value={isCheck}
      />
      {label && (
        <label htmlFor={label} className="mx-2 pointer">
          {label}
        </label>
      )}

      <ErrorText error={error} />
    </>
  );
};

export default CheckSwitch;
