import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Heading from "../../components/ui/Heading";
import Paginations from "../../components/ui/Paginations";
import { Get } from "../../api/method";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { dateDMY } from "../../hooks/DateFormate";
import DownloadBtn from "../../components/buttons/DownloadBtn";
import IconPrimary from "../../components/buttons/IconPrimary";
import { FaLinkedinIn } from "react-icons/fa";
import { rootUrl } from "../../api/apiUrl";
import ViewBtn from "../../components/buttons/ViewBtn";
import DDFilterByUrl from "../../components/ui/DDFilterByUrl";
import Input from "../../components/ui/Input";

const Affiliates = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(0);
  const [search, setSearch] = useState("");

  const { data, error, isLoading, isError } = Get(
    "key",
    `AffiliateRegister/Index?page=${currentPage}&pagesize=${dataPerPage}&status=${status}&query=${search}`
  );

  let list = data?.data;

  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading text="Affiliates Lead" />

        <div className="d-flex justify-content-end">
          <DDFilterByUrl
            label=""
            placeholder="Select Status"
            url="WebsiteLeadStatus"
            defaultValue={status}
            action={setStatus}
            className="mb-3 me-2"
          />
          <Input
            placeholder="Search..."
            defaultValue={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-3 me-2"
          />{" "}
        </div>
      </div>

      <div className="card">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <ErrorMessage message={error.message} />
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>LinkedIn</th>
                  <th>Resume</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {list?.models?.map((item, i) => (
                  <tr key={i}>
                    <td>{dateDMY(item.createdOn)}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>
                      {item.city}, {item.countryName}
                    </td>
                    <td>
                      <a href={item.linkedInProfile} target="blank">
                        <IconPrimary
                          size={24}
                          Icon={FaLinkedinIn}
                          className="p-3px mx-1"
                        />
                      </a>
                    </td>
                    <td>
                      <a href={rootUrl + item.resumeFile} target="blank">
                        <DownloadBtn />
                      </a>
                    </td>
                    <td>{item.status}</td>
                    <td>
                      <ViewBtn
                        data={{
                          getUrl: `AffiliateRegister/${item.id}`,
                          postUrl: `AffiliateRegister/Update`,
                        }}
                        path={`/lead/webDetails`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginations
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataPerPage={dataPerPage}
              setDataPerPage={setDataPerPage}
              totalData={list?.totalEntity}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Affiliates;
