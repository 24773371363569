import { lazy } from "react";
import PrivateRoute from "./layout/PrivateRoute";
import ReviewList from "./reviews/ReviewList";
import ReviewAdd from "./reviews/ReviewAdd";
import ReviewEdit from "./reviews/ReviewEdit";
import TeamList from "./UappTeam/TeamList";
import TeamAdd from "./UappTeam/TeamAdd";
import TeamEdit from "./UappTeam/TeamEdit";
import BlogList from "./blog/BlogList";
import BlogAdd from "./blog/BlogAdd";
import BlogEdit from "./blog/BlogEdit";
import CategoryList from "./category/CategoryList";
import CategoryAdd from "./category/CategoryAdd";
import CategoryEdit from "./category/CategoryEdit";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const OurStory = lazy(() => import("./allPage/ourStory/index"));
const Home = lazy(() => import("./allPage/Home/Index.js"));

const webRoute = {
  path: "/web",
  element: <PrivateRoute />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: "/web/index",
      element: <Dashboard />,
    },
    // //////// Page Start ////////////
    {
      path: "/web/ourStory",
      element: <OurStory />,
    },
    {
      path: "/web/home",
      element: <Home />,
    },
    // //////// Page End ////////////
    {
      path: "/web/blog",
      element: <BlogList />,
    },
    {
      path: "/web/blog/add",
      element: <BlogAdd />,
    },
    {
      path: "/web/blog/edit/:id",
      element: <BlogEdit />,
    },
    {
      path: "/web/category",
      element: <CategoryList />,
    },
    {
      path: "/web/category/add",
      element: <CategoryAdd />,
    },
    {
      path: "/web/category/edit/:id",
      element: <CategoryEdit />,
    },
    {
      path: "/web/review",
      element: <ReviewList />,
    },
    {
      path: "/web/review/add",
      element: <ReviewAdd />,
    },
    {
      path: "/web/review/edit/:id",
      element: <ReviewEdit />,
    },
    {
      path: "/web/uapp-team",
      element: <TeamList />,
    },
    {
      path: "/web/uapp-team/add",
      element: <TeamAdd />,
    },
    {
      path: "/web/uapp-team/edit/:id",
      element: <TeamEdit />,
    },
  ],
};

export default webRoute;
