import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const GroupButton = ({ list, defaultValue, setValue, action }) => {
  const handleChange = (item) => {
    setValue(item.id);
    action && action();
  };

  return (
    <>
      <ButtonGroup aria-label="Basic example" className="w-100">
        {list?.map((item, i) => (
          <Button
            key={i}
            onClick={() => handleChange(item)}
            className={
              defaultValue === item.id
                ? `border bg-secondary text-white`
                : `border bg-white text-black`
            }
          >
            {item.name}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default GroupButton;
