import { AiOutlineUser } from "react-icons/ai";

const leadMenuConsultants = [
  {
    title: "Consultants",
    path: "/lead/consultants",
    Icon: AiOutlineUser,
  },
];

export default leadMenuConsultants;
