import React from "react";
import { FaLink } from "react-icons/fa6";
// import CheckSwitch from "../../../components/ui/CheckSwitch";

const ConnectionCard = ({
  key,
  Icon,
  iconColor,
  text,
  connectionType,
  value,
  actionActive,
}) => {
  return (
    <>
      <div key={key} className="d-inline-block mr-12px mb-1">
        <div className="custom-border pl-12px pr-12px pt-10px pb-10px d-flex align-items-center">
          <div className="d-flex align-items-center">
            {Icon && <Icon size={24} color={iconColor} className="me-2" />}
            <b className="fs-14px">{text}</b>

            {connectionType && (
              <>
                <FaLink size={16} className="gray-500 mx-2" />
                <b className="fs-14px">
                  {connectionType}
                </b>
              </>
            )}
          </div>
          {/* <span className="ml-12px">
            <CheckSwitch
              register={() => {}}
              defaultValue={value}
              action={actionActive}
            />
          </span> */}
        </div>
      </div>
    </>
  );
};

export default ConnectionCard;
