import React from "react";
import settingMenu from "../../components/menus/settingMenu";
import NestedMenu from "../../components/layout/NestedMenu";

const SideBar = ({ action }) => {
  return (
    <>
      <NestedMenu menu={settingMenu} action={action} />
    </>
  );
};

export default SideBar;
