import React, { useState } from "react";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import { Modal } from "react-bootstrap";
import ConvertStudentForm from "./ConvertStudentForm";
import SubHeading from "../../../components/ui/SubHeading";

const ConvertStudent = ({ id, student, isConvert, refetch }) => {
  const [modalForConvertStudent, setModalForConvertStudent] = useState(false);

  const defaultData = {
    consultantId: student?.consultantId ? student?.consultantId : 1,
    universityCountryId: 1,
    firstName: student?.name,
    lastName: "",
    email: student?.email,
  };

  return (
    <>
      {isConvert && (
        <div className="card-min mb-0">
          <SubHeading text="Do you want to convert as a student ?" />
          <ButtonPrimary
            text="Convert"
            action={() => setModalForConvertStudent(true)}
          />
        </div>
      )}

      <Modal
        show={modalForConvertStudent}
        onHide={() => setModalForConvertStudent(false)}
        centered
      >
        <Modal.Body>
          <ConvertStudentForm
            id={id}
            defaultData={defaultData}
            refetch={refetch}
            action={() => setModalForConvertStudent(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConvertStudent;
