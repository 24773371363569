import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Heading from "../../components/ui/Heading";
import Paginations from "../../components/ui/Paginations";
import { WebGet } from "../../api/method";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { dateDMY } from "../../hooks/DateFormate";

const Partners = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, error, isLoading, isError } = WebGet(
    "key",
    `BecomePartnerLead/Index?page=${currentPage}&pagesize=${dataPerPage}`
  );

  let list = data?.data;
  return (
    <>
      <Heading text="Partners Lead" />

      <div className="card">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <ErrorMessage message={error.message} />
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Institute </th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Country </th>
                  <th>Contact Person</th>
                </tr>
              </thead>
              <tbody>
                {list?.models?.map((item, i) => (
                  <tr key={i}>
                    <td>{dateDMY(item.createdOn)}</td>
                    <td>{item.countryName}</td>
                    <td>{item.emailAddress}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.countryName}</td>
                    <td>{item.pointOfContact}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginations
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataPerPage={dataPerPage}
              setDataPerPage={setDataPerPage}
              totalData={list?.totalEntity}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Partners;
