import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import ButtonLight from "../../components/buttons/ButtonLight";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { toast } from "react-toastify";
import Input from "../../components/ui/Input";
import UploadImage from "../../components/ui/UploadImage";
import TextArea from "../../components/ui/TextArea";
import { rootUrl } from "../../api/apiUrl";
import DDByWebUrl from "../../components/ui/DDByWebUrl";
import CheckOne from "../../components/ui/CheckOne";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    id: yup.number(),
    name: yup.string().required("Required"),
    designation: yup.string().required("Required"),
    content: yup.string().required("Required"),
    ratings: yup.number().required().min(1).max(5),
    reviewFrom: yup.number().required().min(1, "Required"),
    isPublished: yup.boolean(),
  })
  .shape({
    avaterFile: yup.mixed(),
    companyLogoFile: yup.mixed(),
  });

const BlogForm = ({
  mutateAsync,
  defaultData,
  submitPath,
  navigatePath,
  refetch,
}) => {
  const navigate = useNavigate();
  const [ReviewBy, setReviewBy] = useState(defaultData.reviewFrom);
  const [isSubmit, setIsSubmit] = useState(false);
  const [companyLogoFile, setcompanyLogoFile] = useState();
  const [avaterFile, setavaterFile] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const handleLogo = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const base64 = reader.result;
      setcompanyLogoFile({
        fileContent: base64,
        fileType: file.type,
        size: file.size,
        fileName: file.name,
      });
    };
    // reader.readAsBinaryString(file);
    reader.readAsDataURL(file);
  };

  const handleavater = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const base64 = reader.result;
      setavaterFile({
        fileContent: base64,
        fileType: file.type,
        size: file.size,
        fileName: file.name,
      });
    };
    // reader.readAsBinaryString(file);
    reader.readAsDataURL(file);
  };

  const onSubmit = (formData) => {
    const submitData = {
      id: formData.id,
      name: formData.name,
      designation: formData.designation,
      content: formData.content,
      ratings: formData.ratings,
      reviewFrom: formData.reviewFrom,
      isPublished: formData.isPublished,
      companyLogoFile: companyLogoFile,
      avaterFile: avaterFile,
    };
    setIsSubmit(true);
    mutateAsync({
      path: submitPath,
      formData: submitData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
          navigate(navigatePath);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });

    setIsSubmit(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("id")} value={defaultData.id} />
        <Row>
          <Col md={6}>
            <DDByWebUrl
              register={register}
              label="Review from"
              name="reviewFrom"
              placeholder=""
              url="ReviewFrom"
              defaultValue={ReviewBy}
              error={errors?.reviewFrom?.message}
              action={setReviewBy}
            />

            <Input
              label="Name"
              type="text"
              name="name"
              placeholder="Name"
              register={register}
              defaultValue={defaultData.name}
              error={errors?.name?.message}
            />
            <Input
              label="Designation"
              type="text"
              name="designation"
              placeholder="Designation"
              register={register}
              defaultValue={defaultData.designation}
              error={errors?.designation?.message}
            />
            <UploadImage
              label="Company Logo"
              name="companyLogoFile"
              register={register}
              file64={companyLogoFile?.fileContent}
              id="companyLogoFile"
              onChange={(e) => handleLogo(e)}
              defaultValue={rootUrl + defaultData.companyLogo}
              error={errors?.companyLogoFile?.message}
            />

            <UploadImage
              label="Avater"
              name="avaterFile"
              register={register}
              file64={avaterFile?.fileContent}
              id="avaterFile"
              onChange={(e) => handleavater(e)}
              defaultValue={rootUrl + defaultData.avater}
              error={errors?.avaterFile?.message}
            />

            <TextArea
              label="Content"
              type="text"
              name="content"
              placeholder="Write a review"
              register={register}
              defaultValue={defaultData.content}
              error={errors?.content?.message}
            />

            <Input
              label="Ratings"
              type="number"
              name="ratings"
              placeholder="Ratings"
              register={register}
              defaultValue={defaultData.ratings}
              error={errors?.ratings?.message}
            />

            <CheckOne
              label="Is Published"
              name="isPublished"
              register={register}
              defaultValue={defaultData.isPublished}
              error={errors?.isPublished?.message}
            />

            <Row>
              <Col>
                <ButtonLight
                  className="w-100"
                  action={() => navigate(navigatePath)}
                />
              </Col>
              <Col>
                <ButtonPrimary
                  className="w-100"
                  text="Save"
                  buttonStatus={isSubmit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BlogForm;
