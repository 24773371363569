import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideMenu = ({ title, Icon, path, action, img }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      <Link
        onClick={action}
        to={path}
        className={`sidemenu ${currentPath === path && "sidemenu-active"}`}
      >
        {img ? <img src={img} alt="" /> : Icon && <Icon size={24} />}

        <p className="fs-16px pl-8px mb-0">{title}</p>
      </Link>
    </>
  );
};

export default SideMenu;
