import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Paginations = ({
  currentPage,
  setCurrentPage,
  dataPerPage,
  setDataPerPage,
  totalData,
}) => {
  let initialPage = Math.ceil(totalData / dataPerPage);
  const list = [10, 20, 30, 50, 100, 1000];

  const [pageList, setPageList] = useState(initialPage);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(initialPage);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (pageList > 6) {
      currentPage > 3 ? setFirst(currentPage - 3) : setFirst(1);
      pageList - 3 > currentPage ? setLast(currentPage + 3) : setLast(pageList);
    }
  }, [currentPage, pageList]);

  useEffect(() => {
    let items = [];
    for (let number = first; number <= last; number++) {
      items.push(number);
    }
    setPages(items);
  }, [first, last]);

  const handleChange = (e) => {
    setDataPerPage(e.target.value);
    setCurrentPage(1);
    setPageList(Math.ceil(totalData / e.target.value));
    setLast(Math.ceil(totalData / e.target.value));
  };

  return (
    <div className="overflowX-auto mt-16px ">
      {pages?.length > 0 && (
        <div className="d-flex justify-content-between align-items-center">
          <Pagination className="mb-0 me-5">
            {/* {currentPage !== 1 && (
              <>
                <Pagination.First onClick={() => setCurrentPage(1)}>
                  <AiOutlineDoubleLeft />
                </Pagination.First>
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <AiOutlineLeft />
                </Pagination.Prev>
              </>
            )} */}
            {currentPage !== 1 ? (
              <>
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <AiOutlineLeft />
                </Pagination.Prev>
              </>
            ) : (
              <Pagination.Prev className="d-none">
                <AiOutlineLeft />
              </Pagination.Prev>
            )}

            {pages.map((number, i) => (
              <Pagination.Item
                key={i}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
                className="mx-1 d-block"
              >
                {number}
              </Pagination.Item>
            ))}

            {currentPage !== pageList ? (
              <>
                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <AiOutlineRight />
                </Pagination.Next>
              </>
            ) : (
              <Pagination.Next className="d-none">
                <AiOutlineRight />
              </Pagination.Next>
            )}
            {/* {currentPage !== pageList && (
              <>
                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <AiOutlineRight />
                </Pagination.Next>
                <Pagination.Last onClick={() => setCurrentPage(pageList)}>
                  <AiOutlineDoubleRight />
                </Pagination.Last>
              </>
            )} */}
          </Pagination>
          <div className="d-flex justify-content-start align-item-center">
            <p className="mt-6px fs-16px me-1 mb-0"> Show:</p>

            <Form.Group className="mb-0">
              <Form.Select
                defaultValue={dataPerPage}
                onChange={handleChange}
                className="mw-70px"
              >
                {list?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          {/* <span className="fw-700 mt-6px"> Total: {totalData}</span> */}
        </div>
      )}
    </div>
  );
};

export default Paginations;
