export const inputform = {
  formGroupId: 14,
  title: "Registration - UAPP Open Day ",
  referenceId: "",
  description:
    "Discover your academic future in the UK! Join us for an exclusive event showcasing study opportunities for the September Intake 2024. Connect with top UK universities, explore programs, and gain valuable insights into admissions, scholarships, and student life. Don't miss this chance to take the next step towards your educational goals!",
  sourceId: 14,
  sourceType: 6,
  formFields: [
    {
      formGroupFieldId: 155,
      label: "Full name",
      value: "",
      error: "",
      isRequired: false,
      type: 1,
      options: [],
    },
    {
      formGroupFieldId: 156,
      label: "Email",
      value: "",
      error: "",
      isRequired: false,
      type: 1,
      options: [],
    },
    {
      formGroupFieldId: 157,
      label: "Phone number(With country code)",
      value: "",
      error: "",
      isRequired: true,
      type: 1,
      options: [],
    },
    {
      formGroupFieldId: 158,
      label: "Level of Education",
      value: "",
      error: "",
      isRequired: true,
      type: 4,
      options: [
        "Secondary School/High School",
        "Undergraduate ",
        "Postgraduate ",
        "Others",
      ],
    },
    {
      formGroupFieldId: 159,
      label: "Field of Interest",
      value: "",
      error: "",
      isRequired: true,
      type: 1,
      options: [],
    },
    {
      formGroupFieldId: 160,
      label: "How did you hear about this event?",
      value: "",
      error: "",
      isRequired: true,
      type: 5,
      options: [
        "Social Media",
        "Email Invitation",
        "Word of Mouth ",
        "UAPP Website",
        "Other",
      ],
    },
    {
      formGroupFieldId: 161,
      label:
        "Would you like to receive updates about future events and study opportunities? ",
      value: "",
      error: "",
      isRequired: true,
      type: 4,
      options: ["Yes", "No"],
    },
    {
      formGroupFieldId: 162,
      label: "Do you want to provide any additional comments?",
      value: "",
      error: "",
      isRequired: true,
      type: 1,
      options: [],
    },
  ],
};
