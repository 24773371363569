import React from "react";
import { rootUrl } from "../../api/apiUrl";

const ProfilePic = ({ filrUrl, className }) => {
  return (
    <img
      src={rootUrl + filrUrl}
      className={`upload-user-profile rounded-circle ${className}`}
      alt=""
    />
  );
};

export default ProfilePic;
