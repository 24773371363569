import React from "react";
import loginLogo from "../../assets/img/logo.svg";
import notFound from "../../assets/img/404.svg";
import { Link } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { BsArrowLeftShort } from "react-icons/bs";
// import BackBtn from "../buttons/BackBtn";

const NotFound = () => {
  const back = () => {
    window.history.back();
  };

  return (
    <div className="card vh-100">
      <Link to="/">
        <img src={loginLogo} alt="" width="150px" />
      </Link>
      <div className="text-center my-5 py-5">
        <img src={notFound} alt="" className="mb-5" />
        <h1 className="fw-700 my-4">Page Not Found </h1>
        <p className="fw-400 fs-14px">
          The page you are looking for doesn't exist.
          <br />
          Here are some helpful links:
        </p>

        <ButtonPrimary Icon={BsArrowLeftShort} text="Go Back" action={back} />
      </div>
    </div>
  );
};

export default NotFound;
