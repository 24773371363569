import React, { useState } from "react";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import EditBtn from "../../components/buttons/EditBtn";
import { WebGet } from "../../api/method";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { Col, Row, Table } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import ProfilePic from "../../components/ui/ProfilePic";
import GroupButton from "../../components/buttons/GroupButton";
import DeleteBtnWeb from "../../components/buttons/DeleteBtnWeb";

const ReviewList = () => {
  const [reviews, setReviews] = useState("0");
  const { data, error, isLoading, isError, refetch } = WebGet(
    "key",
    `reviewAdmin/getAll/${reviews}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  let list = data.data;

  return (
    <>
      <div className="text-end mb-30px">
        <Link to={`/web/review/add`}>
          <ButtonPrimary Icon={AiOutlinePlus} text="Add Review" />
        </Link>
      </div>
      <Row className="mb-3">
        <Col md={4}>
          <GroupButton
            list={[
              { id: "0", name: "All" },
              { id: "1", name: "Students" },
              { id: "2", name: "Institutions" },
              { id: "3", name: "Consultants" },
            ]}
            defaultValue={reviews}
            setValue={setReviews}
          />
        </Col>
      </Row>
      {list.length > 0 && (
        <div className="table-div">
          <Table responsive>
            <thead>
              <tr>
                <th>Logo</th>
                <th>Personal Info</th>
                <th>From</th>
                <th>Contact</th>
                <th>Rating</th>
                <th>Is Published</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <ProfilePic filrUrl={item.companyLogo} />
                  </td>
                  <td className="d-flex justify-content-start">
                    <ProfilePic filrUrl={item.avater} className="me-1" />
                    <span>
                      <b>{item.name}</b>
                      <br />
                      {item.designation}
                    </span>
                  </td>
                  <td>{item.reviewFromName}</td>
                  <td>{item.review}</td>
                  <td>{item.ratings}</td>
                  <td>{item.isPublished ? "True" : "False"}</td>

                  <td>
                    <div className="d-flex justify-content-end">
                      <EditBtn path={`/web/review/edit/${item.id}`} />
                      <DeleteBtnWeb
                        submitPath={`ReviewAdmin/Delete/${item.id}`}
                        refetch={refetch}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <p className=" text-center fw-700"> Total: {list.length}</p>
        </div>
      )}
    </>
  );
};

export default ReviewList;
