import React from "react";
import NestedMenu from "../../components/layout/NestedMenu";
import webMenu from "../../components/menus/webMenu";

const SideBar = ({ action }) => {
  return (
    <>
      <NestedMenu menu={webMenu} action={action} />
    </>
  );
};

export default SideBar;
