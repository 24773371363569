import { lazy } from "react";
import Layout from "./layout/Layout";
import FormSubmit from "./FormSubmit";
import Setting from "../pageLead/setting/Setting";
import TokenClose from "./auth/TokenClose";
import TokenToLeadSetting from "./auth/TokenToLeadSetting";
import AssignConsultant from "../pageLead/assignConsultant/AssignConsultant";
import TokenToLeadConsultant from "./auth/TokenToLeadConsultant";

const Login = lazy(() => import("./auth/Login"));
const Token = lazy(() => import("./auth/Token"));

const homeRoute = {
  path: "/",
  element: <Layout />,
  children: [
    {
      index: true,
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/token/:token",
      element: <Token />,
    },
    {
      path: "/tokenClose/:token",
      element: <TokenClose />,
    },
    {
      path: "/tokenToLeadSetting/:token",
      element: <TokenToLeadSetting />,
    },
    {
      path: "/tokenToLeadConsultant/:token",
      element: <TokenToLeadConsultant />,
    },
    {
      path: "/form/:slug/:ref",
      element: <FormSubmit />,
    },
    {
      path: "/leadSetting",
      element: <Setting />,
    },
    {
      path: "/leadAssignConsultant",
      element: <AssignConsultant />,
    },
  ],
};

export default homeRoute;
