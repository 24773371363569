import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Get } from "../../../api/method";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import TextBeside from "../../UI/TextBeside";
import WebStatus from "./WebStatus";
import Timeline from "./Timeline";
import { rootUrl } from "../../../api/apiUrl";

const WebDetails = () => {
  const location = useLocation();
  const props = location?.state;

  const { data, error, isLoading, isError, refetch } = Get(
    "key",
    `/${props?.getUrl}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const pageData = data?.data;
  return (
    <>
      <Row>
        <Col xxl="5" lg="6">
          <div className="card bg-f2ecfd">
            {pageData?.createdOn && (
              <TextBeside title="Date" text={pageData?.createdOn} />
            )}
            {pageData?.name && (
              <TextBeside title="Name" text={pageData?.name} />
            )}
            {pageData?.email && (
              <TextBeside title="Email" text={pageData?.email} />
            )}
            {pageData?.phone && (
              <TextBeside title="Phone" text={pageData?.phone} />
            )}
            {pageData?.universityName && (
              <TextBeside title="University" text={pageData?.universityName} />
            )}
            {pageData?.courseName && (
              <TextBeside title="Course" text={pageData?.courseName} />
            )}
            {pageData?.currentEducationYear && (
              <TextBeside
                title="Current Year"
                text={pageData?.currentEducationYear}
              />
            )}

            {pageData?.countryName && (
              <TextBeside title="Country" text={pageData?.countryName} />
            )}
            {pageData?.city && (
              <TextBeside title="City" text={pageData?.city} />
            )}

            {pageData?.resumeUrl && (
              <TextBeside
                title="Resume"
                text="Download"
                link={rootUrl + pageData?.resumeUrl}
              />
            )}
          </div>

          {pageData?.status < 3 && (
            <WebStatus
              data={{
                id: pageData?.id,
                status: pageData?.status,
                statusName: pageData?.statusName,
              }}
              postUrl={props?.postUrl}
              refetch={refetch}
            />
          )}
        </Col>
        <Col xxl="5" lg="6">
          {pageData?.logs.length > 0 && <Timeline data={pageData?.logs} />}
        </Col>
      </Row>
    </>
  );
};

export default WebDetails;
