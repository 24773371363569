import axios from "axios";
import { uappUrl, rootApi, webApi, userApi } from "./apiUrl";

const uappClient = axios.create({ baseURL: uappUrl });
const crmClient = axios.create({ baseURL: rootApi });
const userClient = axios.create({ baseURL: userApi });
const webClient = axios.create({ baseURL: webApi });

export const uappReq = async ({ ...options }) => {
  const res = await uappClient(options);
  return res;
};

export const crmReq = async ({ ...options }) => {
  const res = await crmClient(options);
  return res;
};

export const userReq = async ({ ...options }) => {
  const res = await userClient(options);
  return res;
};

export const webReq = async ({ ...options }) => {
  const res = await webClient(options);
  return res;
};
