import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UappPost } from "../../../api/method";
import Input from "../../../components/ui/Input";
import CheckOne from "../../../components/ui/CheckOne";
import { ReferenceId } from "../../../components/User";

const schema = yup.object({
  id: yup.string(),
  consultantId: yup.string().required(),
  fromDate: yup.string().required("Start Date is required"),
  toDate: yup.string(),
  untilBack: yup.boolean(),
});

const UnAvailabilityForm = ({ refetch }) => {
  const [isUntilBack, setIsUntilBack] = useState(false);
  const [toDateError, setToDateError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const { mutateAsync } = UappPost();

  const defaultData = {
    id: 0,
    consultantId: ReferenceId(),
    fromDate: null,
    toDate: null,
    untilBack: true,
    isActive: true,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    if (!isUntilBack && formData.toDate === null) {
      setToDateError("End date is required");
    } else if (
      !isUntilBack &&
      new Date(formData.fromDate) > new Date(formData.toDate)
    ) {
      setToDateError("End date not be before Form date");
    } else {
      const submitData = {
        id: formData.id,
        consultantId: formData.consultantId,
        fromDate: formData.fromDate,
        toDate: formData.toDate ? formData.toDate : null,
        untilBack: isUntilBack,
        isActive: true,
      };

      setIsSubmit(true);
      mutateAsync({
        path: "/ConsultantLeadAvailibility/Submit",
        formData: submitData,
      })
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            refetch && refetch();
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((error) => {
          toast.error("Error:", error.message);
        });
      setIsSubmit(false);
    }
  };

  return (
    <>
      <p className="gray-500">Set specific dates when you won’t be available</p>
      <Row>
        <Col md={7} lg={5}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="hidden"
              name="id"
              register={register}
              defaultValue={defaultData.id}
            />

            <Input
              type="hidden"
              name="consultantId"
              register={register}
              defaultValue={defaultData.consultantId}
            />

            <Input
              type="date"
              register={register}
              label="Start Date"
              name="fromDate"
              defaultValue={defaultData.fromDate}
              error={errors?.fromDate?.message}
            />

            <CheckOne
              label="Unavailable until you back"
              name="untilBack"
              // register={register}
              defaultValue={isUntilBack}
              onChange={() => setIsUntilBack(!isUntilBack)}
              error={errors?.untilBack?.message}
            />

            {!isUntilBack && (
              <Input
                type="date"
                register={register}
                label="End Date"
                name="toDate"
                defaultValue={defaultData.toDate}
                error={toDateError}
              />
            )}

            <ButtonPrimary buttonStatus={isSubmit} />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default UnAvailabilityForm;
