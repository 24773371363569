import createPersistedState from "use-persisted-state";
const useUserState = createPersistedState("user");
const useAuthState = createPersistedState("auth");
const useThemeState = createPersistedState("theme");

const useData = () => {
  const [user, setUser] = useUserState(null);
  const [token, setToken] = useAuthState(null);
  const [theme, setTheme] = useThemeState(true);

  const signOut = () => {
    setUser(null);
    setToken(null);
  };

  return {
    user,
    setUser,
    token,
    setToken,
    theme,
    setTheme,
    signOut,
  };
};

export default useData;
