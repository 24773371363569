import React from "react";

const ButtonLight = ({
  Icon,
  iconOrder = 1,
  text = "Close",
  type = "reset",
  className,
  action,
}) => {
  return (
    <>
      <button
        className={`btn-theme-light ${className}`}
        onClick={action && action}
        type={type}
      >
        {Icon && iconOrder === 1 ? (
          <Icon size={20} className={text !== "" && "me-1"} />
        ) : null}
        {text}
        {Icon && iconOrder === 2 ? <Icon size={20} className="ms-1" /> : null}
      </button>
    </>
  );
};

export default ButtonLight;
