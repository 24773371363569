import React, { useState } from "react";
import Heading from "../../../components/ui/Heading";
import { UappGet, UappPut } from "../../../api/method";
import { ReferenceId } from "../../../components/User";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import UnAvailabilityForm from "./UnAvailabilityForm";
import { toast } from "react-toastify";
import ButtonLight from "../../../components/buttons/ButtonLight";
import { FaClipboard } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import LogList from "./LogList";
import SubHeading from "../../../components/ui/SubHeading";

const Availability = () => {
  const { mutateAsync } = UappPut();
  const [modalLog, setModalLog] = useState(false);
  const { data, error, isLoading, isError, refetch } = UappGet(
    "key",
    `ConsultantLeadAvailibility/CheckAvailibility/${ReferenceId()}`
  );

  const onSubmit = (id) => {
    mutateAsync({
      path: `/ConsultantLeadAvailibility/BecomAvailable/${id}`,
      formData: "",
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const pageData = data.result;

  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading text="Manage Lead availability " />
        <div>
          <ButtonLight
            Icon={FaClipboard}
            text="All Logs"
            action={() => setModalLog(true)}
          />
        </div>
      </div>
      <div className="card">
        {pageData ? (
          <>
            <div className="lead-availability d-flex justify-content-between">
              <span>
                Your unavailability is set from {pageData.fromDate} to{" "}
                {pageData.toDate
                  ? pageData.toDate
                  : `until you are
                back`}
              </span>
              <span
                className="underline pointer"
                onClick={() => onSubmit(pageData.consultantId)}
              >
                Cancel now
              </span>
            </div>
          </>
        ) : (
          <UnAvailabilityForm refetch={refetch} />
        )}
      </div>

      <Modal show={modalLog} onHide={() => setModalLog(false)} centered>
        <Modal.Body>
          <LogList action={() => setModalLog(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Availability;
