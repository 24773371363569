import React from "react";
import TeamForm from "./TeamForm";
import { WebPost } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";

const TeamAdd = () => {
  const { mutateAsync } = WebPost();

  const defaultData = {
    id: 0,
    name: "",
    avater: "",
    avaterFile: [],
    designation: "",
    linkedlnUrl: "",
    displayOrder: "",
  };

  return (
    <>
      <BackBtn text="Back to Team" path="/web/uapp-team" />
      <TeamForm
        mutateAsync={mutateAsync}
        defaultData={defaultData}
        submitPath="/UappTeamAdmin/Create"
        navigatePath="/web/uapp-team"
        refetch={() => {}}
      />
    </>
  );
};

export default TeamAdd;
