import React from "react";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import { Get } from "../../../api/method";
import NoteForm from "./NoteForm";
import CardHeading from "../../../components/ui/CardHeading";
import { PiNote } from "react-icons/pi";

const Note = ({ id }) => {
  const { data, error, isLoading, isError, refetch } = Get(
    "key",
    `/LeadNote?id=${id}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const pageData = data?.data;
  return (
    <>
      <div className="card">
        <CardHeading Icon={PiNote} text="Note" />
        <div className="mh-250px mb-1">
          {pageData.map((item, i) => (
            <p key={i} className="border-start p-2">
              {item.noteContent}
            </p>
          ))}
        </div>

        <NoteForm id={id} refetch={refetch} />
      </div>
    </>
  );
};

export default Note;
