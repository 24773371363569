import React from "react";
import Heading from "../../components/ui/Heading";
import { Col, Row } from "react-bootstrap";
import Meta from "./meta/Meta";
import ConnectionFilter from "./connection/ConnectionFilter";
import { Consultant } from "../../components/User";
import Availability from "./availability/Availability";

const Setting = () => {
  return (
    <>
      <Row>
        <Col md={7}>
          <div className="card">
            <Heading text="Connect with Social Media" />
            <Meta />
          </div>
        </Col>
      </Row>

      {Consultant() && <Availability />}

      {!Consultant() && (
        <div className="mt-67px">
          <ConnectionFilter />
        </div>
      )}
    </>
  );
};

export default Setting;
