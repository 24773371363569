import { FiTarget } from "react-icons/fi";
import leadMenuAdmin from "./leadMenuAdmin";

const mainmenuLead = [
  {
    title: "Lead",
    Icon: FiTarget,
    path: "/lead",
    submenu: leadMenuAdmin,
  },
];

export default mainmenuLead;
