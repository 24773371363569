import React from "react";
import { Form } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import ErrorText from "./ErrorText";

const UploadImage = ({
  name,
  label,
  register,
  defaultValue,
  file,
  file64,
  id,
  onChange,
  error,
}) => {
  return (
    <Form.Group className="mb-3">
      {label && (
        <>
          <Form.Label>{label}</Form.Label> <br />
        </>
      )}
      <Form.Label htmlFor={`inputImg${id}`} className="pointer">
        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt=""
            className="upload-user-profile rounded-circle"
          />
        ) : file64 ? (
          <img
            // src={"data:image/jpeg;base64," + btoa(file64)}
            src={file64}
            alt=""
            className="upload-user-profile rounded-circle"
          />
        ) : defaultValue ? (
          <img
            src={defaultValue}
            alt=""
            className="upload-user-profile rounded-circle"
          />
        ) : (
          <FiPlus size={24} className="add rounded-circle" />
        )}
      </Form.Label>
      <Form.Control
        type="file"
        accept="image/jpg, image/png"
        // accept="image/*"
        id={`inputImg${id}`}
        {...register(name)}
        onChange={onChange}
        className="d-none"
      />
      <ErrorText error={error} />
    </Form.Group>
  );
};

export default UploadImage;
