import { FiCalendar } from "react-icons/fi";
import { FaWpforms } from "react-icons/fa";
import settingMenu from "./settingMenu";

const mainmenuEvent = [
  {
    title: "Event",
    Icon: FiCalendar,
    path: "/event",
  },
  {
    title: "Form Builder",
    Icon: FaWpforms,
    path: "/formbuild",
    submenu: settingMenu,
  },
];

export default mainmenuEvent;
