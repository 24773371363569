import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
// import ThemeMode from "../ui/ThemeMode";
import { useContextData } from "../../api/context";
import Notification from "../ui/Notification";
import { uappUrl } from "../../api/apiUrl";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";

const UserMenu = () => {
  const value = useContextData();
  return (
    <>
      <div className="d-flex align-items-center">
        {value.user && <Notification />}
        {/* <ThemeMode /> */}
        {value.user ? (
          <Dropdown>
            <Dropdown.Toggle variant="none" className="p-0 border-0">
              <div className="d-flex align-items-center">
                <span className="fs-12px me-1 fw-600 d-none d-md-block">
                  {value.user.fullName}
                </span>
                {value.user.displayPhoto ? (
                  <img
                    src={uappUrl + value.user.displayPhoto}
                    alt=""
                    width="32"
                    height="32"
                    className="rounded-circle"
                  />
                ) : (
                  <FaUserCircle size={32} />
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {/* <Link className="dropdown-item" to="/event">
                Event
              </Link>
              <Link className="dropdown-item" to="/lead">
                Lead
              </Link>
              <Link className="dropdown-item" to="/formbuild">
                Form Builder
              </Link> */}
              <Link onClick={value.signOut} className="dropdown-item" to="/">
                <span className="text-danger">
                  Logout <FiLogOut />
                </span>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Link className="dropdown-item" to="/login">
            Login
          </Link>
        )}
      </div>
    </>
  );
};

export default UserMenu;
