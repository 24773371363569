import React from "react";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import EditBtn from "../../components/buttons/EditBtn";
import { WebGet } from "../../api/method";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { AiOutlinePlus } from "react-icons/ai";
import ProfilePic from "../../components/ui/ProfilePic";
import { Table } from "react-bootstrap";
import DeleteBtnWeb from "../../components/buttons/DeleteBtnWeb";
import { FiLinkedin } from "react-icons/fi";

const TeamList = () => {
  const { data, error, isLoading, isError, refetch } = WebGet(
    "key",
    `UappTeamAdmin/GetAll`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  let list = data.data;

  return (
    <>
      <div className="text-end mb-30px">
        <Link to={`/web/uapp-team/add`}>
          <ButtonPrimary Icon={AiOutlinePlus} text="Add Team" />
        </Link>
      </div>

      {list.length > 0 && (
        <div className="table-div">
          <Table responsive>
            <thead>
              <tr>
                <th>D.O.</th>
                <th>Avater</th>
                <th>LinkedIn Url</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, i) => (
                <tr key={i}>
                  <td>{item.displayOrder}</td>
                  <td className="d-flex justify-content-start">
                    <ProfilePic filrUrl={item.avater} className="me-1" />
                    <span>
                      <b>{item.name}</b>
                      <br />
                      {item.designation}
                    </span>
                  </td>

                  <td>
                    <a href={item.linkedlnUrl} target="blank">
                      <FiLinkedin size={24} />
                    </a>
                  </td>

                  <td>
                    <div className="d-flex justify-content-end">
                      <EditBtn path={`/web/uapp-team/edit/${item.id}`} />
                      <DeleteBtnWeb
                        submitPath={`UappTeamAdmin/Delete/${item.id}`}
                        refetch={refetch}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <p className="text-center fw-700"> Total: {list.length}</p>
        </div>
      )}
    </>
  );
};

export default TeamList;
