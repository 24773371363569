import React from "react";
import Pointer from "../../components/buttons/Pointer";
import CardHeading from "../../components/ui/CardHeading";
import { AiOutlineProfile } from "react-icons/ai";

const ApplicationTimeline = ({ data }) => {
  return (
    <div className="card">
      <CardHeading Icon={AiOutlineProfile} text="Application Timeline" />
      <ul className="profile-timeline">
        {data?.map((timeline, i) => (
          <li className="border-start" key={i}>
            <div className="mb-8px d-flex align-items-center  m-t-28px">
              <span className="teal-500">{timeline.status}</span>
              <span className="mr-6px ml-6px mb-2px">
                <Pointer color="#64748B" />
              </span>
              <span> {timeline.date}</span>
            </div>
            <div className="bg-gray-100 gray-500 p-8px rounded-1">
              {timeline.details}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ApplicationTimeline;
