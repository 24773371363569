import { lazy } from "react";
import PrivateRoute from "./layout/PrivateRoute";

const FormList = lazy(() => import("./form/FormList"));
const FormAdd = lazy(() => import("./form/FormAdd"));
const FormEdit = lazy(() => import("./form/FormEdit"));

const settingsRoute = {
  path: "/formbuild",
  element: <PrivateRoute />,
  children: [
    {
      index: true,
      element: <FormList />,
    },
    {
      path: "/formbuild",
      element: <FormList />,
    },
    {
      path: "/formbuild/add",
      element: <FormAdd />,
    },
    {
      path: "/formbuild/edit",
      element: <FormEdit />,
    },
  ],
};

export default settingsRoute;
