import React from "react";
import { Delete } from "../../api/method";
import { Col, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import ButtonLight from "./ButtonLight";
import ButtonPrimary from "./ButtonPrimary";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";

const DeleteBtn = ({
  text = "Are you confirm delete it ?",
  submitPath,
  refetch,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { mutateAsync } = Delete();

  const onSubmit = (formData) => {
    mutateAsync({
      path: submitPath,
      formData: formData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
          setModalShow(false);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <>
      <span
        className="pointer text-danger ml-4px"
        onClick={() => setModalShow(true)}
      >
        <AiOutlineDelete size={20} />
      </span>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
      >
        <Modal.Body>
          <h4 className="mb-4 text-center">{text}</h4>
          <Row>
            <Col>
              <ButtonPrimary
                className="w-100"
                text="Yes"
                action={() => onSubmit()}
              />
            </Col>
            <Col>
              <ButtonLight
                className="w-100"
                text="No"
                action={() => setModalShow(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteBtn;
