import React from "react";
import CategoryForm from "./CategoryForm";
import { WebPost } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";

const CategoryAdd = () => {
  const { mutateAsync } = WebPost();

  const defaultData = {
    id: 0,
    name: "",
    details: "",
  };

  return (
    <>
      <BackBtn text="Back to Category" path="/web/category" />
      <CategoryForm
        mutateAsync={mutateAsync}
        defaultData={defaultData}
        submitPath="/BlogCategoryAdmin/Create"
        navigatePath="/web/category"
        refetch={() => {}}
      />
    </>
  );
};

export default CategoryAdd;
