import { AiOutlineTeam, AiOutlineUser } from "react-icons/ai";

const leadMenuaffiliate = [
  {
    title: "Affiliates",
    path: "/lead/affiliates",
    Icon: AiOutlineUser,
  },
  {
    title: "Ambassadors",
    path: "/lead/ambassadors",
    Icon: AiOutlineTeam,
  },
];

export default leadMenuaffiliate;
