import React from "react";
import leadMenuAdmin from "../../components/menus/leadMenuAdmin";
import NestedMenu from "../../components/layout/NestedMenu";
import leadMenu from "../../components/menus/leadMenu";
import { AdminUsers } from "../../components/User";
import { useContextData } from "../../api/context";
import leadMenuaffiliate from "../../components/menus/leadMenuaffiliate";
import leadMenuConsultants from "../../components/menus/leadMenuConsultants";
import leadMenuPartners from "../../components/menus/leadMenuPartners";
import leadMenuProviders from "../../components/menus/leadMenuProviders";

const SideBar = ({ action }) => {
  const value = useContextData();

  const menu =
    value.user.email === "affiliateambassadorleadmanager@gmail.com"
      ? leadMenuaffiliate
      : value.user.email === "consultantleadmanager@gmail.com"
      ? leadMenuConsultants
      : value.user.email === "partnerleadmanager@gmail.com"
      ? leadMenuPartners
      : value.user.email === "providerleadmanager@gmail.com"
      ? leadMenuProviders
      : AdminUsers()
      ? leadMenuAdmin
      : leadMenu;

  return (
    <>
      <NestedMenu menu={menu} action={action} />
    </>
  );
};

export default SideBar;
