import React from "react";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const ConnectButton = ({ Icon, iconColor, text, url }) => {
  return (
    <div
      className={`custom-border pl-16px pr-16px pt-12px pb-12px mb-16px d-flex justify-content-between align-items-center `}
    >
      <div className="d-flex">
        {Icon && <Icon size={24} color={iconColor} className="me-2" />}
        <b className="fs-16px">{text}</b>
      </div>
      <div>
        <Link to={url} target="blank">
          <ButtonPrimary text="Connect" Icon={AiOutlineRight} iconOrder={2} />
        </Link>
      </div>
    </div>
  );
};

export default ConnectButton;
