import { useQuery, useMutation } from "react-query";
import { useContextData } from "./context";
import { webReq, crmReq, uappReq, userReq } from "./axios";

export const UappGet = (key, path) => {
  const value = useContextData();

  const { status, data, error, isLoading, isError, refetch } = useQuery(
    [
      key,
      {
        path: path,
        headers: {
          Authorization: "Bearer " + value.token,
        },
      },
    ],
    ({ queryKey, signal }) => {
      const { path, headers } = queryKey[1];
      return uappReq({
        method: "GET",
        url: path,
        headers: headers,
        signal,
      });
    }
  );
  return { status, data: data?.data, error, isLoading, isError, refetch };
};

export const UappPost = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    uappReq({
      method: "POST",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const UappPut = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    uappReq({
      method: "PUT",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const UserGet = (key, path) => {
  const value = useContextData();

  const { status, data, error, isLoading, isError, refetch } = useQuery(
    [
      key,
      {
        path: path,
        headers: {
          Authorization: "Bearer " + value.token,
        },
      },
    ],
    ({ queryKey, signal }) => {
      const { path, headers } = queryKey[1];
      return userReq({
        method: "GET",
        url: path,
        headers: headers,
        signal,
      });
    }
  );
  return { status, data: data?.data, error, isLoading, isError, refetch };
};

export const UserPost = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    userReq({
      method: "POST",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const Get = (key, path) => {
  const value = useContextData();

  const { status, data, error, isLoading, isError, refetch } = useQuery(
    [
      key,
      {
        path: path,
        headers: {
          Authorization: "Bearer " + value.token,
        },
      },
    ],
    ({ queryKey, signal }) => {
      const { path, headers } = queryKey[1];
      return crmReq({
        method: "GET",
        url: path,
        headers: headers,
        signal,
      });
    }
  );
  return { status, data: data?.data, error, isLoading, isError, refetch };
};

export const Post = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    crmReq({
      method: "POST",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const Put = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    crmReq({
      method: "PUT",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const Delete = () => {
  const value = useContextData();
  return useMutation(({ path }) =>
    crmReq({
      method: "DELETE",
      url: path,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const WebGet = (key, path) => {
  const value = useContextData();

  const { status, data, error, isLoading, isError, refetch } = useQuery(
    [
      key,
      {
        path: path,
        headers: {
          Authorization: "Bearer " + value.token,
        },
      },
    ],
    ({ queryKey, signal }) => {
      const { path, headers } = queryKey[1];
      return webReq({
        method: "GET",
        url: path,
        headers: headers,
        signal,
      });
    }
  );
  return { status, data: data?.data, error, isLoading, isError, refetch };
};

export const WebPost = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    webReq({
      method: "POST",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const WebPut = () => {
  const value = useContextData();
  return useMutation(({ path, formData }) =>
    webReq({
      method: "PUT",
      url: path,
      data: formData,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};

export const WebDelete = () => {
  const value = useContextData();
  return useMutation(({ path }) =>
    webReq({
      method: "DELETE",
      url: path,
      headers: {
        Authorization: "Bearer " + value.token,
      },
    })
  );
};
