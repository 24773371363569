import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Heading from "../../components/ui/Heading";
import SubHeading from "../../components/ui/SubHeading";
import Paginations from "../../components/ui/Paginations";
// import Input from "../../components/ui/Input";
import DDFilterByAppUrl from "../../components/ui/DDFilterByAppUrl";
// import DDByList from "../../components/ui/DDByList";
import DDByObjList from "../../components/ui/DDByObjList";
import { AdminUsers } from "../../components/User";

const Campaign = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventForValue, setEventForValue] = useState("0");

  const [branch, setBranch] = useState(0);
  const [consultant, setConsultant] = useState(0);
  return (
    <>
      <Heading text="Campaign" />

      <div className="card">
        <div className="d-flex justify-content-between align-items-center">
          <SubHeading text="Meta" />
          {/* <Input
            placeholder="Search by Platform"
            defaultValue={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
        </div>
        <Row>
          <Col xl={3} lg={4} xs={6}>
            <DDByObjList
              select="Default"
              register={() => {}}
              label=""
              name=""
              list={[
                { id: 1, name: "Branch" },
                { id: 2, name: "Consultant" },
              ]}
              defaultValue={eventForValue}
              action={setEventForValue}
            />
          </Col>
          {AdminUsers() && (
            <>
              <Col xl={3} lg={4} xs={6}>
                <DDFilterByAppUrl
                  label=""
                  placeholder="Select Branch"
                  url="event/BranchApi/Index"
                  defaultValue={branch}
                  action={setBranch}
                />
              </Col>
              <Col xl={3} lg={4} xs={6}>
                <DDFilterByAppUrl
                  label=""
                  placeholder="Select Consultant"
                  url={`event/ConsultantApi/index?id=${branch}`}
                  defaultValue={consultant}
                  action={setConsultant}
                />
              </Col>
            </>
          )}

          <Col xl={3} lg={4} xs={6}>
            <DDFilterByAppUrl
              label=""
              placeholder="Select Consultant"
              url={`event/ConsultantApi/index?id=${branch}`}
              defaultValue={consultant}
              action={setConsultant}
            />
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Total</th>
              <th>In Progress</th>
              <th>Converted</th>
              <th>Wasted</th>
              <th>Conversion Rate</th>
            </tr>
          </thead>
          <tbody>
            {/* {list?.models?.map((item, i) => ( */}
            {/* <tr key={i}> */}
            <tr>
              <td>Facebook</td>
              <td>3447</td>
              <td>3021</td>
              <td>2153</td>
              <td>23</td>
              <td>23%</td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>3447</td>
              <td>3021</td>
              <td>2153</td>
              <td>23</td>
              <td>23%</td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>3447</td>
              <td>3021</td>
              <td>2153</td>
              <td>23</td>
              <td>23%</td>
            </tr>

            {/* ))} */}
          </tbody>
        </Table>
        <Paginations
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataPerPage={dataPerPage}
          setDataPerPage={setDataPerPage}
          // totalData={data?.totalEntity}
          totalData={105}
        />
      </div>
    </>
  );
};

export default Campaign;
