import React, { useState } from "react";

import Heading from "../../components/ui/Heading";
import Paginations from "../../components/ui/Paginations";
import DDByAppUrl from "../../components/ui/DDByAppUrl";
import { Col, Row, Table } from "react-bootstrap";
import PerformanceChart from "./PerformanceChart";
import DateRangePicker from "../../components/ui/DateRangePicker";
import { currentDate, firstDateMonth } from "../../hooks/DateFormate";

const ConsultantPerformance = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormDate] = useState(firstDateMonth);
  const [toDate, setToDate] = useState(currentDate);

  const [status, setStatus] = useState("");

  return (
    <>
      <div className="d-md-flex justify-content-between align-items-center">
        <Heading text="Consultant performance" />
        <div className="d-flex">
          <DateRangePicker
            formData={formData}
            setFormDate={setFormDate}
            toDate={toDate}
            setToDate={setToDate}
            className="mb-24px me-2"
          />
          <DDByAppUrl
            register={() => {}}
            label=""
            name=""
            placeholder="Branch"
            url="event/BranchApi/Index"
            defaultValue={status}
            action={setStatus}
            className="mb-24px"
          />
        </div>
      </div>

      <Row>
        <Col xl={12} className="order-2 order-xl-1">
          <PerformanceChart />
        </Col>
      </Row>

      <div className="card">
        <Table responsive>
          <thead>
            <tr>
              <th>Consultant Name</th>
              <th>Total</th>
              <th>Converted</th>
              <th>Conversion ratio</th>
            </tr>
          </thead>
          <tbody>
            {/* {list?.models?.map((item, i) => ( */}
            {/* <tr key={i}> */}
            <tr>
              <td>Wade Warren</td>
              <td>30,221</td>
              <td>3021</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Esther Howard</td>
              <td>30,221</td>
              <td>3021</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Esther Howard</td>
              <td>30,221</td>
              <td>3021</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Esther Howard</td>
              <td>30,221</td>
              <td>3021</td>
              <td>0%</td>
            </tr>
            {/* ))} */}
          </tbody>
        </Table>
        <Paginations
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataPerPage={dataPerPage}
          setDataPerPage={setDataPerPage}
          // totalData={data?.totalEntity}
          totalData={105}
        />
      </div>
    </>
  );
};

export default ConsultantPerformance;
