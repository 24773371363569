import React from "react";
import { FiDownload } from "react-icons/fi";

const DownloadBtn = ({ action }) => {
  return (
    <>
      <FiDownload size={17} onClick={action} className="ms-1 teal-500" />
    </>
  );
};

export default DownloadBtn;
