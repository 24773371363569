import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../components/ui/Input";
import { UserGet, UserPost } from "../api/method";
import logo from "../assets/img/logo.svg";
import { toast } from "react-toastify";
import { InputType } from "../components/Constant";
import Radio from "../components/ui/Radio";
import CheckBox from "../components/ui/CheckBox";
import DDByList from "../components/ui/DDByList";
import TextArea from "../components/ui/TextArea";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import { useParams } from "react-router-dom";
import Loading from "../components/ui/Loading";
import ErrorMessage from "../components/ui/ErrorMessage";
import { inputform } from "./json";
import Heading from "../components/ui/Heading";
import SubHeading from "../components/ui/SubHeading";

const FormSubmit = () => {
  const { slug, ref } = useParams();
  const { mutateAsync } = UserPost();
  const { handleSubmit, register, reset } = useForm();
  const [isSubmit, setSubmit] = useState(false);
  const [formFields, setFormFields] = useState(inputform);

  const { data, error, isLoading, isError } = UserGet(
    "key",
    `form?slug=${slug}&reference=${ref}`
  );

  const onFormDataChange = (v, i) => {
    let formData = formFields;
    formData.formFields[i].value = v.toString();
    setFormFields(formData);
  };

  useEffect(() => {
    setFormFields(data);
  }, [data]);

  const onSubmit = async () => {
    let formData = formFields;
    const filterError = formData?.formFields?.filter(
      (item) => item.isRequired && item.value === ""
    );
    filterError.length > 1 &&
      formData?.formFields?.map((item, i) =>
        item.isRequired && item.value === ""
          ? (formData.formFields[i].error = "Required")
          : (formData.formFields[i].error = "")
      );
    setFormFields(formData);
    // formData.formFields[i].value = v.toString();
    setFormFields(formData);
    console.log(filterError);
    console.log(formFields.formFields);
    // console.log(
    //   formData?.formFields?.map(
    //     (item, i) => item.require && item.value === "" && "hi"
    //   )
    // );
    // setSubmit(true);
    // try {
    //   const { status, data } = await mutateAsync({
    //     path: "Form",
    //     formData: formFields,
    //   });
    //   if (status === 200 && data.isSuccess === true) {
    //     reset();
    //     toast.success(data.message);
    //   } else {
    //     toast.warn(data.message);
    //   }
    // } catch (error) {
    //   toast.error("Error :", error.message);
    // } finally {
    //   setSubmit(false);
    // }
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  return (
    <div className="d-flex align-items-center relative overflowY-hidden my-5 py-5">
      <div className="mx-auto" id="login">
        <div className="text-center mb-40px">
          <img src={logo} alt="" width="150px" />
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Heading text={formFields?.title} />
          <SubHeading text={formFields?.description} />

          {formFields?.formFields?.map((item, i) => (
            <div key={i} className="w-100">
              {item.type === InputType.Radio ? (
                <Radio
                  label={item.label}
                  name={item.label}
                  register={register}
                  list={item.options}
                  defaultValue={item.value}
                  action={(v) => onFormDataChange(v, i)}
                />
              ) : item.type === InputType.CheckBox ? (
                <CheckBox
                  label={item.label}
                  name={item.label}
                  register={register}
                  list={item.options}
                  defaultValue={item.value ? item.value.split(",") : []}
                  action={(v) => onFormDataChange(v, i)}
                />
              ) : item.type === InputType.SelectList ? (
                <DDByList
                  register={register}
                  label={item.label}
                  name={item.label}
                  placeholder=""
                  list={item.options}
                  defaultValue={item.value}
                  action={(v) => onFormDataChange(v, i)}
                />
              ) : item.type === InputType.TextArea ? (
                <TextArea
                  label={item.label}
                  name={item.label}
                  onChange={(e) => onFormDataChange(e.target.value, i)}
                />
              ) : (
                <Input
                  label={item.label}
                  type={item.type}
                  onChange={(e) => onFormDataChange(e.target.value, i)}
                />
              )}
            </div>
          ))}

          {formFields?.formFields?.length > 0 && (
            <ButtonPrimary
              text="Submit"
              className="w-100"
              buttonStatus={isSubmit}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default FormSubmit;
