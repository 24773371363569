import React from "react";
import NestedMenu from "./NestedMenu";
import mainmenu from "../menus/mainmenu";
import mainmenuAdmin from "../menus/mainmenuAdmin";
import mainmenuConsultant from "../menus/mainmenuConsultant";
import { AdminUsers, Consultant } from "../User";
import { useContextData } from "../../api/context";
import mainmenuLead from "../menus/mainmenuLead";
import mainmenuEvent from "../menus/mainmenuEvent";
import leadMenuaffiliate from "../menus/leadMenuaffiliate";
import leadMenuConsultants from "../menus/leadMenuConsultants";
import leadMenuPartners from "../menus/leadMenuPartners";
import leadMenuProviders from "../menus/leadMenuProviders";

const MobileMenu = ({ action }) => {
  const value = useContextData();

  const menu =
    value.user.email === "leadmanager@mail.com"
      ? mainmenuLead
      : value.user.email === "eventmanager@mail.com"
      ? mainmenuEvent
      : value.user.email === "affiliateambassadorleadmanager@gmail.com"
      ? leadMenuaffiliate
      : value.user.email === "consultantleadmanager@gmail.com"
      ? leadMenuConsultants
      : value.user.email === "partnerleadmanager@gmail.com"
      ? leadMenuPartners
      : value.user.email === "providerleadmanager@gmail.com"
      ? leadMenuProviders
      : AdminUsers()
      ? mainmenuAdmin
      : Consultant()
      ? mainmenuConsultant
      : mainmenu;

  return (
    <>
      <NestedMenu menu={menu} action={action} />
    </>
  );
};

export default MobileMenu;
