import React, { useEffect } from "react";
import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "./components/ui/Loading";
import NotFound from "./components/ui/NotFound";
import homeRoute from "./pageHome/homeRoute";
import eventRoute from "./pageEvent/eventRoute";
import leadRoute from "./pageLead/leadRoute";
import settingsRoute from "./pageSetting/settingsRoute";
import webRoute from "./pageWeb/webRoute";
import { useContextData } from "./api/context";

const routes = createBrowserRouter([
  homeRoute,
  eventRoute,
  leadRoute,
  settingsRoute,
  webRoute,
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  const value = useContextData();

  useEffect(() => {
    if (value?.user?.expireDate) {
      const currentDate = new Date().toUTCString();
      const expireDate = new Date(value?.user?.expireDate).toUTCString();
      const timeout = new Date(expireDate) - new Date(currentDate);

      setTimeout(() => {
        value.signOut();
        toast.warn("Session End, Please login in again");
      }, timeout);
    }
  }, [value]);

  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer />
      <RouterProvider router={routes} />
    </Suspense>
  );
}

export default App;
