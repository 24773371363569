import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";

const EditBtn = ({ data, path, action }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    data && path && navigate(path, { state: data });
    !data && path && navigate(path);
    action && action();
  };

  return (
    <>
      <span className="pointer gray-500 ml-4px" onClick={handleEdit}>
        <AiOutlineEdit size={20} />
      </span>
    </>
  );
};

export default EditBtn;
