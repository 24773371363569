import { AiTwotoneContainer } from "react-icons/ai";

const settingMenu = [
  {
    title: "All Forms",
    Icon: AiTwotoneContainer,
    path: "/formbuild",
  },
];

export default settingMenu;
