import React from "react";
import { UappGet } from "../../../api/method";
import { ReferenceId } from "../../../components/User";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import SubHeading from "../../../components/ui/SubHeading";
import CloseBtn from "../../../components/buttons/CloseBtn";

const LogList = ({ action }) => {
  const { data, error, isLoading, isError } = UappGet(
    "key",
    `ConsultantLeadAvailibility/${ReferenceId()}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  console.log(data);
  const pageData = data.result;

  return (
    <>
      <div className="d-flex justify-content-between">
        <SubHeading text="Unavailability History" />
        <CloseBtn action={action} />
      </div>
      <div className="h-300px">
        <ul className="profile-timeline">
          {pageData?.map((timeline, i) => (
            <li className="border-start" key={i}>
              <div className="mb-8px d-flex align-items-center m-t-28px">
                <span className="teal-500">
                  {timeline.toDate
                    ? `${
                        (new Date(timeline.toDate) -
                          new Date(timeline.fromDate)) /
                        86400000
                      } Days`
                    : "Until Back"}
                </span>
              </div>
              <div className="bg-gray-100 gray-500 p-8px rounded-1">
                <b>{timeline.fromDate}</b> to{" "}
                <b>{timeline.toDate ? timeline.toDate : "Until Back"}</b>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LogList;
