import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TextBeside from "../UI/TextBeside";
import LeadAssign from "../lead/LeadAssign";
import EditBtn from "../../components/buttons/EditBtn";
import ProfileEdit from "./ProfileEdit";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import CardHeading from "../../components/ui/CardHeading";
import { LiaUserCircle } from "react-icons/lia";
import { UappGet } from "../../api/method";
import SubHeading from "../../components/ui/SubHeading";
import Loading from "../../components/ui/Loading";
import ErrorText from "../../components/ui/ErrorText";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import ConvertStudent from "./convertStudent/ConvertStudent";
import { Consultant } from "../../components/User";

const ProfileInfo = ({ pageData, convertData, refetch, convertRefetch }) => {
  const [modalData, setModalData] = useState();
  const [modalForProfile, setModalForProfile] = useState(false);
  const [modalForAssign, setModalForAssign] = useState(false);

  const { data, error, isLoading, isError } = UappGet(
    "key",
    `event/ConsultantApi/details?id=${pageData?.consultantId}`
  );

  const consultant = data?.data;

  return (
    <>
      <div className="card bg-f2ecfd">
        <div className="d-flex justify-content-between">
          <CardHeading Icon={LiaUserCircle} text="Profile" />
          <EditBtn
            action={() => {
              setModalForProfile(true);
              setModalData({
                id: pageData.id,
                name: pageData.name,
                email: pageData.email,
                phoneNumber: pageData.phoneNumber,
                whatsappNumber: pageData.whatsappNumber
                  ? pageData.whatsappNumber
                  : pageData.phoneNumber,
              });
            }}
          />
        </div>

        <TextBeside title="Name" text={pageData?.name} />
        <TextBeside title="Phone" text={pageData?.phoneNumber} />
        <TextBeside
          title="Whatsapp"
          text={pageData?.whatsappNumber}
          link={`https://web.whatsapp.com/send?phone=${pageData?.whatsappNumber}`}
        />

        <TextBeside
          title="Email"
          text={pageData?.email}
          link={`mailto: ${pageData?.email}`}
        />

        <TextBeside title="Date" text={pageData?.leadDate} />
        <TextBeside title="Country" text={pageData?.country} />

        {pageData?.platformLeadId && (
          <TextBeside title="Platform Id" text={pageData?.platformLeadId} />
        )}

        {!Consultant() && (
          <>
            {pageData?.assignedTo ? (
              <>
                {isLoading ? (
                  <Loading />
                ) : isError ? (
                  <ErrorText error={error.message} />
                ) : (
                  <div
                    className={`card-min mt-16px ${
                      !convertData?.data && "mb-0"
                    }`}
                  >
                    <div className="d-flex justify-content-between">
                      <SubHeading text="Consultant" className="mb-0" />
                      <div>
                        <span
                          className="gray-500 pointer"
                          onClick={() => {
                            setModalForAssign(true);
                            setModalData({
                              leadId: pageData.id,
                              branchId: pageData.branchId,
                              consultantId: pageData.consultantId,
                            });
                          }}
                        >
                          Change
                        </span>
                        <EditBtn
                          action={() => {
                            setModalForAssign(true);
                            setModalData({
                              leadId: pageData.id,
                              branchId: pageData.branchId,
                              consultantId: pageData.consultantId,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <p className="gray-500"> {consultant.fullName}</p>
                    <p className="gray-500 fw-500 mb-0">
                      <MdOutlineMailOutline size={16} className="mr-4px" />
                      {consultant.email}
                    </p>
                    <p className="gray-500 fw-500 mb-0">
                      <AiOutlinePhone size={16} className="mr-4px" />
                      {consultant.phoneNumber}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-16px">
                <TextBeside title="Status" text={pageData?.statusName} />
                <ButtonPrimary
                  text="Assign Consultant"
                  action={() => {
                    setModalForAssign(true);
                    setModalData({
                      leadId: pageData.id,
                      branchId: pageData.branchId,
                      consultantId: pageData.consultantId,
                    });
                  }}
                />
              </div>
            )}
          </>
        )}

        <ConvertStudent
          id={pageData.id}
          student={pageData}
          isConvert={convertData?.data}
          refetch={() => {
            refetch();
            convertRefetch();
          }}
        />
      </div>

      <Modal
        show={modalForProfile}
        onHide={() => setModalForProfile(false)}
        centered
      >
        <Modal.Body>
          <ProfileEdit
            defaultData={modalData}
            refetch={refetch}
            action={() => setModalForProfile(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalForAssign}
        onHide={() => setModalForAssign(false)}
        centered
      >
        <Modal.Body>
          <LeadAssign
            data={modalData}
            refetch={refetch}
            action={() => setModalForAssign(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileInfo;
