import React from "react";
import { useContextData } from "../../api/context";
import { Navigate } from "react-router-dom";
import Layout from "./Layout";

const PrivateRoute = () => {
  const value = useContextData();
  // return value.user ? <Layout /> : <Layout />;
  return value.user ? <Layout /> : <Navigate to="/" />;
};

export default PrivateRoute;
