import React from "react";
import { WebGet, WebPut } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";
import { useParams } from "react-router-dom";
import ReviewForm from "./ReviewForm";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";

const ReviewEdit = () => {
  const { id } = useParams();
  const { mutateAsync } = WebPut();
  const { data, error, isLoading, isError } = WebGet(
    "key",
    `reviewAdmin/get/${id}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const defaultData = {
    id: data.data.id,
    name: data.data.name,
    companyLogo: data.data.companyLogo,
    companyLogoFile: data.data.companyLogoFile,
    avater: data.data.avater,
    avaterFile: data.data.avaterFile,
    designation: data.data.designation,
    content: data.data.content,
    ratings: data.data.ratings,
    isPublished: data.data.isPublished,
    reviewFrom: data.data.reviewFrom,
    reviewFromName: data.data.reviewFromName,
  };

  return (
    <>
      <BackBtn text="Back to Review" path="/web/review" />
      {defaultData && (
        <ReviewForm
          mutateAsync={mutateAsync}
          defaultData={defaultData}
          submitPath="/ReviewAdmin/Update"
          navigatePath="/web/review"
          refetch={() => {}}
        />
      )}
    </>
  );
};

export default ReviewEdit;
