import React from "react";
import BlogForm from "./BlogForm";
import { WebPost } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";

const BlogAdd = () => {
  const { mutateAsync } = WebPost();

  const defaultData = {
    id: 0,
    name: "",
    companyLogo: "",
    companyLogoFile: [],
    avater: "",
    avaterFile: [],
    designation: "",
    content: "",
    ratings: 1,
    isPublished: "",
    reviewFrom: "",
    reviewFromName: "",
  };

  return (
    <>
      <BackBtn text="Back to Review" path="/web/review" />
      <BlogForm
        mutateAsync={mutateAsync}
        defaultData={defaultData}
        submitPath="/ReviewAdmin/Create"
        navigatePath="/web/review"
        refetch={() => {}}
      />
    </>
  );
};

export default BlogAdd;
