import React, { useEffect } from "react";
import { useContextData } from "../../api/context";
import { useNavigate, useParams } from "react-router-dom";
import loginLogo from "../../assets/img/loginLogo.svg";
import Vector from "../../assets/img/Vector.svg";
import Vector1 from "../../assets/img/Vector1.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { uappUrl } from "../../api/apiUrl";

const TokenToLeadConsultant = () => {
  const { token } = useParams();
  const value = useContextData();
  const navigate = useNavigate();

  useEffect(() => {
    if (value?.user !== null) {
      navigate("/leadAssignConsultant");
    }
  }, [value, navigate]);

  useEffect(() => {
    if (token) {
      try {
        axios
          .get(`${uappUrl}auth/login?token=${token}`, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            if (res?.data.isSuccess === true) {
              value.setUser(res?.data);
              value.setToken(res?.data.token);
              navigate("/leadAssignConsultant");
            } else {
              toast.warn(res?.data.message);
            }
          });
      } catch (error) {
        toast.error("Error :", error.message);
      }
    }
  }, [navigate, token, value]);

  return (
    <div className="bg-teal-500 vh-100 d-flex align-items-center relative overflowY-hidden">
      <div className="absolute top-0 end-0">
        <img src={Vector} alt="" />
      </div>

      <div className="mx-auto" id="login">
        <div className="text-center mb-40px">
          <img src={loginLogo} alt="" />
        </div>

        <h1 className="text-center text-white fw-900">
          Processing your login request...
        </h1>
      </div>

      <div className="absolute bottom-0 start-0 w-25">
        <img src={Vector1} alt="" className="w-100" />
      </div>
    </div>
  );
};

export default TokenToLeadConsultant;
