import React from "react";
import { Get, Put } from "../../../api/method";
import ConnectionPages from "./ConnectionPages";
import Loading from "../../../components/ui/Loading";
import ErrorMessage from "../../../components/ui/ErrorMessage";
import Heading from "../../../components/ui/Heading";
import CloseBtn from "../../../components/buttons/CloseBtn";

const ConnectionEdit = ({ id, refetch, action }) => {
  const { mutateAsync } = Put();

  const { data, error, isLoading, isError } = Get("key", `/ConsultantMetaConnection/UnassignedPage/${id}`);

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  console.log(data);

  return (
    <>

        <div className="d-flex justify-content-between">
          <Heading text="Meta following pages" />
          <CloseBtn action={action} />
        </div>


      <ConnectionPages
        id={id}
        pages={data?.data}
        mutateAsync={mutateAsync}
        refetch={refetch}
        action={action}
      />
    </>
  );
};

export default ConnectionEdit;
