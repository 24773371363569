import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Put } from "../../api/method";
import SubHeading from "../../components/ui/SubHeading";
import DDFilterByAppUrl from "../../components/ui/DDFilterByAppUrl";

const schema = yup.object({
  consultantId: yup.number(),
});

const AssignForm = ({ refetch }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { mutateAsync } = Put();

  const [consultantId, setConsultantId] = useState(0);
  const [consultantIdError, setConsultantIdError] = useState("");

  const defaultData = {
    consultantId: 0,
  };

  const { register, handleSubmit, reset } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    // const submitData = new FormData();
    // submitData.append("consultantId", formData.consultantId);
    const submitData = {
      consultantId: consultantId,
    };

    console.log(submitData);

    if (consultantId === 0) {
      setConsultantIdError("Please select a consultant");
    } else {
      setConsultantIdError("");
      setIsSubmit(true);
      mutateAsync({
        path: `Consultant/Assign?id=${consultantId}`,
        formData: submitData,
      })
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            reset();
            refetch();
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((error) => {
          toast.error("Error:", error.message);
        });
      setIsSubmit(false);
    }
  };

  return (
    <div className="mb-4">
      <SubHeading text="Select consultant form uapp to work on lead management" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={5}>
            <DDFilterByAppUrl
              register={register}
              label=""
              placeholder="Select Consultant"
              url={`event/ConsultantApi/get-employee-consultants`}
              name="consultantId"
              defaultValue={consultantId}
              error={consultantIdError}
              setError={setConsultantIdError}
              action={setConsultantId}
            />
          </Col>
          <Col sm={2}>
            <ButtonPrimary
              className="w-100"
              text="Assign"
              buttonStatus={isSubmit}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AssignForm;
