import React from "react";
import { AiOutlineProfile } from "react-icons/ai";
import CardHeading from "../../../components/ui/CardHeading";
import Pointer from "../../../components/buttons/Pointer";

const Timeline = ({ data }) => {
  return (
    <div className="card">
      <CardHeading Icon={AiOutlineProfile} text="Timeline" />
      <ul className="profile-timeline">
        {data?.map((timeline, i) => (
          <li className="border-start" key={i}>
            <div className="mb-8px d-flex align-items-center">
              <span className="teal-500">{timeline.statusName}</span>
              <span className="mr-6px ml-6px mb-2px">
                <Pointer color="#64748B" />
              </span>
              <span> {timeline.date}</span>
            </div>
            <div className="bg-gray-100 gray-500 p-8px rounded-1">
              {timeline.note}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Timeline;
