import React from "react";
import DatePicker from "./DatePicker";

const DateRangePicker = ({
  formData,
  setFormDate,
  toDate,
  setToDate,
  label,
  value,
  onChange,
  className = "mb-3",
}) => {
  return (
    <>
      <span className={`card-border d-flex p-8px w-290px ${className}`}>
        <DatePicker
          label=""
          value={formData}
          onChange={(e) => {
            setFormDate(e.target.value);
          }}
        />
        <b>-</b>
        <DatePicker
          label=""
          value={toDate}
          onChange={(e) => {
            setToDate(e.target.value);
          }}
        />
      </span>
    </>
  );
};

export default DateRangePicker;
