import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const Layout = () => {
  return (
    <>
      <Row className="bg-white vh-100">
        <Col md={12}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default Layout;
