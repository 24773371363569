import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import ErrorText from "./ErrorText";

const GroupInput = ({
  label,
  groupLabel,
  type = "text",
  name,
  register,
  placeholder,
  defaultValue,
  error,
}) => {
  return (
    <Form.Group className="mb-3">
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Text id="basic-addon1">{groupLabel}</InputGroup.Text>
        <Form.Control
          type={type}
          {...register(name)}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        <ErrorText error={error} />
      </InputGroup>
    </Form.Group>
  );
};

export default GroupInput;
