import React from "react";
import { Table } from "react-bootstrap";
import SubHeading from "../../components/ui/SubHeading";
// import Paginations from "../../components/ui/Paginations";
import { Get, Put } from "../../api/method";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import DeleteBtn from "../../components/buttons/DeleteBtn";
import AssignForm from "./AssignForm";
import CheckSwitch from "../../components/ui/CheckSwitch";
import { toast } from "react-toastify";

const AssignConsultant = () => {
  const { mutateAsync } = Put();
  // const [dataPerPage, setDataPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);

  const { data, error, isLoading, isError, refetch } = Get(
    "key",
    `Consultant/Index`
  );

  const onSubmit = (id) => {
    mutateAsync({
      path: `Consultant/deactivate?id=${id}`,
      formData: "",
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });
  };

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  let list = data?.data;

  return (
    <>
      <AssignForm refetch={refetch} />
      <SubHeading text="Assigned Consultants" />

      <div className="card">
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Total</th>
              <th>In Progress</th>
              <th>Converted</th>
              <th>Conversation Rate</th>
              <th>Response Rate </th>
              <th>Active</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {list?.map((item, i) => (
              <tr key={i}>
                <td>{item.name}</td>
                <td>{item.totalLead}</td>
                <td>{item.inProcessLead}</td>
                <td>{item.convertedLead}</td>
                <td>{item.conversionRate}</td>
                {/* <td>{item.responseRate}</td> */}
                <td>0</td>
                <td>
                  <CheckSwitch
                    register={() => {}}
                    defaultValue={item.isActive}
                    action={() => onSubmit(item.id)}
                  />
                </td>
                {/* <td>
                  <DeleteBtn
                    text="Are you confirm unassign this consultant ?"
                    submitPath={`Consultant/unassign?id=${item?.id}`}
                    refetch={refetch}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
        {/* <Paginations
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataPerPage={dataPerPage}
          setDataPerPage={setDataPerPage}
          totalData={105}
        /> */}
      </div>
    </>
  );
};

export default AssignConsultant;
