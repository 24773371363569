import React, { useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import UserMenu from "./UserMenu";
import logo from "../../assets/img/logo.svg";
import mLogo from "../../assets/img/mLogo.svg";
import CloseBtn from "../buttons/CloseBtn";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Header area  */}
      <Navbar expand="lg" fixed="top" className="navbar">
        <Navbar.Toggle onClick={handleShow} className="border-0" />
        <Link to="/" className="mr-20px">
          <img src={mLogo} alt="logo" className="d-block d-lg-none" />
          <img src={logo} alt="logo" className="d-none d-lg-block" />
        </Link>

        <div className="me-auto d-none d-lg-block">
          <Nav>
            <HeaderMenu />
          </Nav>
        </div>
        <UserMenu />
      </Navbar>

      {/* Side Menu Components for Mobile Device */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="dashboard-layout-sidebar"
      >
        <Offcanvas.Body>
          <div className="d-flex align-items-center justify-content-between mb-4 mx-2">
            <img src={mLogo} alt="logo" className="d-block d-lg-none" />
            <CloseBtn action={handleClose} />
          </div>
          <MobileMenu action={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
