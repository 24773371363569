import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Put } from "../../api/method";
import Input from "../../components/ui/Input";
import CloseBtn from "../../components/buttons/CloseBtn";
import TextArea from "../../components/ui/TextArea";
import Status from "../../components/ui/Status";
import DDByUrl from "../../components/ui/DDByUrl";
import CardHeading from "../../components/ui/CardHeading";
import { IoMdCheckboxOutline } from "react-icons/io";
import CheckOne from "../../components/ui/CheckOne";
import { Consultant } from "../../components/User";

const schema = yup.object({
  leadId: yup.string(),
  statusId: yup.number().min(1, "Required"),
  message: yup.string().required("Note is required"),
});

const LeadStatus = ({ data, refetch, action }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [statusId, setStatusId] = useState(data?.status);
  const [check, setCheck] = useState(false);
  const [checkError, setCheckError] = useState("");

  const { mutateAsync } = Put();
  const defaultData = {
    leadId: data.leadId,
    statusId: data.status,
    message: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const submitData = {
      leadId: formData.leadId,
      statusId: formData.statusId,
      message: formData.message,
    };

    if (statusId === "1" && !check) {
      setCheckError("Required");
    } else {
      setCheckError("");
      setIsSubmit(true);
      mutateAsync({
        path: "Leads/UpdateStatus",
        formData: submitData,
      })
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            refetch && refetch();
            action && action();
            reset();
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((error) => {
          toast.error("Error:", error.message);
        });
      setIsSubmit(false);
    }
  };

  return (
    <div className={!action && "card"}>
      <div className="d-flex justify-content-between">
        <CardHeading Icon={IoMdCheckboxOutline} text="Status" />

        {action ? (
          <CloseBtn action={action} />
        ) : (
          <span>
            <Status text={data.statusName} />
          </span>
        )}
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="hidden"
          name="leadId"
          register={register}
          defaultValue={defaultData.leadId}
        />

        <DDByUrl
          register={register}
          label="Update lead status below"
          name="statusId"
          placeholder="Select Status"
          url="LeadStatus/details"
          defaultValue={defaultData.statusId}
          error={errors?.statusId?.message}
          action={setStatusId}
        />

        <TextArea
          label="Note"
          register={register}
          name="message"
          placeholder="Write an short note"
          defaultValue={defaultData.message}
          error={errors?.message?.message}
        />
        {statusId === "1" && (
          <CheckOne
            label={
              !Consultant()
                ? "I agree that the process will unassign the consultant from this lead."
                : " I agree that the process will unassign myself from the lead"
            }
            defaultValue={check}
            onChange={(e) => {
              setCheck(e.target.checked);
              e.target.checked ? setCheckError("") : setCheckError("Required");
            }}
            error={checkError}
          />
        )}
        {Consultant() && (
          <p className="text-orange fs-12px fw-600">
            In case you want to send back the lead to admin for reassignment,
            select status as “New”
          </p>
        )}
        <ButtonPrimary
          className={action && "w-100"}
          text="Done"
          buttonStatus={isSubmit}
        />
      </Form>
    </div>
  );
};

export default LeadStatus;
