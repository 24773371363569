import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Heading from "../../components/ui/Heading";
import Paginations from "../../components/ui/Paginations";
import { WebGet } from "../../api/method";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";

const Providers = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, error, isLoading, isError } = WebGet(
    "key",
    `ProviderRegistration/Index?page=${currentPage}&pagesize=${dataPerPage}`
  );

  let list = data?.data;

  return (
    <>
      <Heading text="Providers Lead" />

      <div className="card">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <ErrorMessage message={error.message} />
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Institue </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Country</th>
                  <th>Add. Message</th>
                </tr>
              </thead>
              <tbody>
                {list?.models?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.providerName}</td>
                    <td>
                      {item.nameTittleId +
                        " " +
                        item.firstName +
                        " " +
                        item.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.country}</td>
                    <td>{item.additionalMessage}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Paginations
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataPerPage={dataPerPage}
              setDataPerPage={setDataPerPage}
              totalData={list?.totalEntity}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Providers;
