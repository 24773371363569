import {
  AiOutlineAppstore,
  AiOutlineContainer,
  AiOutlineSetting,
  AiOutlineStar,
} from "react-icons/ai";

const webMenu = [
  {
    title: "Overview",
    Icon: AiOutlineAppstore,
    path: "/web/index",
  },
  {
    title: "Blogs",
    Icon: AiOutlineContainer,
    path: "/web/index",
    submenu: [
      {
        title: "All Blogs",
        Icon: AiOutlineAppstore,
        path: "/web/index",
      },
      {
        title: "Add New",
        Icon: AiOutlineAppstore,
        path: "/web/index",
        // path: "/web/blog/add",
      },
      {
        title: "Categories",
        Icon: AiOutlineAppstore,
        path: "/web/category",
      },
    ],
  },
  {
    title: "Reviews",
    Icon: AiOutlineStar,
    path: "/web/index",
    submenu: [
      {
        title: "Reviews",
        Icon: AiOutlineAppstore,
        path: "/web/review",
      },
      {
        title: "Video",
        Icon: AiOutlineAppstore,
        path: "/web/index",
      },
      {
        title: "Success Stories",
        Icon: AiOutlineAppstore,
        path: "/web/index",
      },
    ],
  },
  {
    title: "Common",
    Icon: AiOutlineSetting,
    path: "/web/index",
    submenu: [
      {
        title: "UAPP Team",
        Icon: AiOutlineAppstore,
        path: "/web/uapp-team",
      },
      {
        title: "Study Country",
        Icon: AiOutlineAppstore,
        path: "/web/index",
      },
      {
        title: "Education Level",
        Icon: AiOutlineAppstore,
        path: "/web/index",
      },
    ],
  },
  {
    title: "Pages",
    Icon: AiOutlineAppstore,
    path: "/web/index",
    submenu: [
      {
        title: "Home",
        Icon: AiOutlineAppstore,
        path: "/web/home",
      },
      {
        title: "Our Story",
        Icon: AiOutlineAppstore,
        path: "/web/ourStory",
      },
    ],
  },
];

export default webMenu;
