import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavMenu = ({ title, Icon, path, action }) => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const incomePath = path.split("/")[1];
  return (
    <>
      <Link
        onClick={action}
        to={path}
        className={`navmenu ${currentPath === incomePath && "navmenu-active"}`}
      >
        <Icon size={24} /> <span className="fs-16px">{title}</span>
      </Link>
    </>
  );
};

export default NavMenu;
