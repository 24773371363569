import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SideBar from "./SideBar";
import Header from "../../components/layout/Header";

const Layout = () => {
  return (
    <>
      <Header />
      <Row className="content">
        <Col>
          <Row className="setting">
            <Col className="bg-light px-0 border-end rounded-start">
              <div className="setting-card-overflow">
                <div className="pl-16px pr-16px pt-32px pb-32px">
                  <SideBar />
                </div>
              </div>
            </Col>
            <Col className="bg-white px-0 rounded-end overflow-auto">
              <div className="setting-card-overflow">
                <div className="setting-outlet-padding">
                  <Outlet />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Layout;
