import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/ui/Input";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { toast } from "react-toastify";
import TextArea from "../../components/ui/TextArea";
import ButtonLight from "../../components/buttons/ButtonLight";

const schema = yup.object({
  id: yup.string(),
  name: yup.string().required("Name is required"),
  details: yup.string().required("Details is required"),
});

const CategoryForm = ({
  mutateAsync,
  defaultData,
  submitPath,
  navigatePath,
  refetch,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const submitData = {
      id: formData.id,
      name: formData.name,
      details: formData.details,
    };

    setIsSubmit(true);
    mutateAsync({
      path: submitPath,
      formData: submitData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
          navigate(navigatePath);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });

    setIsSubmit(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("id")} value={defaultData.id} />
        <Row>
          <Col md={6}>
            <Input
              label="Category"
              type="text"
              name="name"
              placeholder="Name"
              register={register}
              defaultValue={defaultData.name}
              error={errors?.name?.message}
            />

            <TextArea
              label="Details"
              type="text"
              name="details"
              placeholder="Write a review"
              register={register}
              defaultValue={defaultData.details}
              error={errors?.details?.message}
            />

            <Row>
              <Col>
                <ButtonLight
                  className="w-100"
                  action={() => navigate(navigatePath)}
                />
              </Col>
              <Col>
                <ButtonPrimary className="w-100" buttonStatus={isSubmit} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CategoryForm;
