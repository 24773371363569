import React from "react";
import { Form } from "react-bootstrap";
import { Get } from "../../api/method";
import ErrorText from "./ErrorText";
import Loading from "./Loading";

const DDByUrl = ({
  label,
  name,
  select = "Select",
  url,
  defaultValue,
  error,
  register,
  placeholder,
  action,
}) => {
  const { data, isLoading } = Get("key", url);

  const list = data?.data;
  const handleChange = (e) => {
    action && action(e.target.value);
  };

  if (isLoading) return <Loading />;
  return (
    <>
      <Form.Group className="mb-3">
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Select
          {...register(name)}
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          <option value={0}>{placeholder ? placeholder : select} </option>

          {!isLoading &&
            list?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
        </Form.Select>
        <ErrorText error={error} />
      </Form.Group>
    </>
  );
};

export default DDByUrl;
