import React from "react";

const IconPrimary = ({ Icon, size = 20, className = "p-2px", action }) => {
  const handleChange = () => {
    action && action();
  };

  return (
    <>
      {Icon && (
        <Icon
          size={size}
          className={`icon-btn pointer ml-4px rounded-circle ${className}`}
          onClick={() => handleChange()}
        />
      )}
    </>
  );
};

export default IconPrimary;
