import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import ButtonLight from "../../components/buttons/ButtonLight";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { toast } from "react-toastify";
import Input from "../../components/ui/Input";
import UploadImage from "../../components/ui/UploadImage";
import { rootUrl } from "../../api/apiUrl";
import { useNavigate } from "react-router-dom";
import GroupInput from "../../components/ui/GroupInput";

const schema = yup
  .object({
    id: yup.number(),
    name: yup.string().required("Required"),
    designation: yup.string().required("Required"),
    linkedlnUrl: yup.string().required("Required"),
    displayOrder: yup.number().required().min(1, "Required"),
  })
  .shape({
    avaterFile: yup.mixed(),
  });

const TeamForm = ({
  mutateAsync,
  defaultData,
  submitPath,
  navigatePath,
  refetch,
}) => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [avaterFile, setavaterFile] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultData: defaultData,
    resolver: yupResolver(schema),
  });

  const handleavater = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      const base64 = reader.result;
      setavaterFile({
        fileContent: base64,
        fileType: file.type,
        size: file.size,
        fileName: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (formData) => {
    const submitData = {
      id: formData.id,
      name: formData.name,
      designation: formData.designation,
      linkedlnUrl: formData.linkedlnUrl,
      displayOrder: formData.displayOrder,
      avaterFile: avaterFile,
    };
    setIsSubmit(true);
    mutateAsync({
      path: submitPath,
      formData: submitData,
    })
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          refetch();
          navigate(navigatePath);
        } else {
          toast.warn(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error:", error.message);
      });

    setIsSubmit(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("id")} value={defaultData.id} />
        <Row>
          <Col md={6}>
            <Input
              label="Name"
              type="text"
              name="name"
              placeholder="Name"
              register={register}
              defaultValue={defaultData.name}
              error={errors?.name?.message}
            />
            <Input
              label="Designation"
              type="text"
              name="designation"
              placeholder="Designation"
              register={register}
              defaultValue={defaultData.designation}
              error={errors?.designation?.message}
            />

            <UploadImage
              label="Avater"
              name="avaterFile"
              register={register}
              file64={avaterFile?.fileContent}
              id="avaterFile"
              onChange={(e) => handleavater(e)}
              defaultValue={rootUrl + defaultData.avater}
              error={errors?.avaterFile?.message}
            />

            <GroupInput
              label="Linkedln Url"
              groupLabel="http://"
              type="text"
              name="linkedlnUrl"
              placeholder="Write linkedin url"
              register={register}
              defaultValue={defaultData.linkedlnUrl}
              error={errors?.linkedlnUrl?.message}
            />

            <Input
              label="Display Order"
              type="number"
              name="displayOrder"
              placeholder="Display Order"
              register={register}
              defaultValue={defaultData.displayOrder}
              error={errors?.displayOrder?.message}
            />

            <Row>
              <Col>
                <ButtonLight
                  className="w-100"
                  action={() => navigate(navigatePath)}
                />
              </Col>
              <Col>
                <ButtonPrimary
                  className="w-100"
                  text="Save"
                  buttonStatus={isSubmit}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TeamForm;
