import React from "react";
import { WebGet, WebPut } from "../../api/method";
import BackBtn from "../../components/buttons/BackBtn";
import { useParams } from "react-router-dom";

import TeamForm from "./TeamForm";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";

const TeamEdit = () => {
  const { id } = useParams();
  const { mutateAsync } = WebPut();
  const { data, error, isLoading, isError } = WebGet(
    "key",
    `UappTeamAdmin/get/${id}`
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const defaultData = {
    id: data.data.id,
    name: data.data.name,
    avater: data.data.avater,
    avaterFile: data.data.avaterFile,
    designation: data.data.designation,
    linkedlnUrl: data.data.linkedlnUrl,
    displayOrder: data.data.displayOrder,
  };

  return (
    <>
      <BackBtn text="Back to Team" path="/web/uapp-team" />
      {defaultData && (
        <TeamForm
          mutateAsync={mutateAsync}
          defaultData={defaultData}
          submitPath="/UappTeamAdmin/Update"
          navigatePath="/web/uapp-team"
          refetch={() => {}}
        />
      )}
    </>
  );
};

export default TeamEdit;
