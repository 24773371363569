import React from "react";
import Loading from "../../components/ui/Loading";
import ErrorMessage from "../../components/ui/ErrorMessage";
import { WebGet } from "../../api/method";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteBtnWeb from "../../components/buttons/DeleteBtnWeb";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { AiOutlinePlus } from "react-icons/ai";
import EditBtn from "../../components/buttons/EditBtn";

const CategoryList = () => {
  const { data, error, isLoading, isError, refetch } = WebGet(
    "key",
    "/BlogCategoryAdmin/GetAll"
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorMessage message={error.message} />;

  const list = data?.data;

  return (
    <>
      <div className="text-end mb-30px">
        <Link to={`/web/category/add`}>
          <ButtonPrimary Icon={AiOutlinePlus} text="Add Category" />
        </Link>
      </div>

      {list.length > 0 && (
        <div className="table-div">
          <Table responsive>
            <thead>
              <tr>
                <th>Category</th>
                <th>Details</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, i) => (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.details}</td>

                  <td>
                    <div className="d-flex justify-content-end">
                      <EditBtn path={`/web/category/edit/${item.id}`} />
                      <DeleteBtnWeb
                        submitPath={`BlogCategoryAdmin/Delete/${item.id}`}
                        refetch={refetch}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <p className="text-center fw-700"> Total: {list.length}</p>
        </div>
      )}
    </>
  );
};

export default CategoryList;
