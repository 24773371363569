import React from "react";
import { MdOutlineNotifications } from "react-icons/md";

const Notification = () => {
  const handleNotification = () => {
    // alert("clicked Notification");
  };

  return (
    <span className="mr-24px pointer">
      <MdOutlineNotifications onClick={handleNotification} size={24} />
    </span>
  );
};

export default Notification;
